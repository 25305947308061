import React from "react";
import { Link } from "react-router-dom";
import { BASE_PATH } from "../../utils/constants";

// Bootstrap components
// import Col from "react-bootstrap/Col";

// Utils
import formatMoney from "../../utils/formatMoney";

export default function ProductCard({ product }) {
  if (product) {
    return (
      <div
        className={`products__product col-6 col-md-3 ${product?.tipoCorrea?.nombre?.toLowerCase()} ${product?.genero?.nombre?.toLowerCase()} ${product?.marca?.nombre?.toLowerCase()} ${
          product?.tipo?.data !== null
            ? product?.tipo?.nombre.toLowerCase()
            : ""
        } ${
          product?.calibre !== null
            ? product?.calibre?.nombre.toLowerCase()
            : ""
        }`}
      >
        <div className="products__product-holder">
          <div className="products__product__image-container">
            <img
              src={`${process.env.REACT_APP_API_URL}${product.imagenes[0]?.image?.url}`}
              // src={product.imagenes[0].image.url}
              alt={product.nombre}
              className="products__product__image"
            />
          </div>
          <div className="products__product__content">
            <h5 className="products__product__name">{product.nombre}</h5>
            <p className="my-1 my-lg-0">{formatMoney(product.precio)} MXN</p>
            <p>
              <span>Stock:</span>
              <span> {product.stock} pza(s)</span>
            </p>
          </div>
        </div>
        <div className="products__product__overlay">
          <div className="products__product__overlay__image-container">
            <Link to={`/product/${product.id}`}>
              {product.imagenes.length >= 2 ? (
                <img
                  src={`${process.env.REACT_APP_API_URL}${product.imagenes[1]?.image?.url}`}
                  // src={product.imagenes[1].url}
                  alt={product.nombre}
                  className="products__product__overlay__image"
                />
              ) : (
                <></>
              )}
            </Link>
          </div>
          <div className="products__product__overlay__content">
            <Link
              to={`/product/${product.id}`}
              className="products__product__overlay__cta"
            >
              <p className="products__product__overlay__cta__text">Detalles</p>
            </Link>
          </div>
        </div>
      </div>
      // <Col lg="3">
      //   <Link to={`/product/${product.id}`} className="product-card">
      //     <div
      //       className="product-card__media"
      //       style={{
      //         backgroundImage: `url( "${BASE_PATH}${product.attributes.imagenes.data[0].attributes.url}" )`,
      //       }}
      //     ></div>
      //     <div className="product-card__body">
      //       <h5>{product.attributes.nombre}</h5>
      //       <p>{formatMoney(product.attributes.precio)}</p>
      //       <p>
      //         <span>Stock:</span>
      //         <span>{product.attributes.stock} pza(s)</span>
      //       </p>
      //     </div>
      //   </Link>
      // </Col>
    );
  } else {
    return <div></div>;
  }
}
