import React, { useState, useEffect, useContext } from "react";

// Context
import { useProducts } from "../context/ProductsContext";
import AlertContext from "../context/AlertContext";

// API
import { getProducts, getHeroCatalogue } from "../api/product";
import { getBrands } from "../api/brand";
import { getWatchBands } from "../api/watch-band";
import { getWatchGenres } from "../api/genre";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Util
import { toggleClass } from "../utils/toggleClass";

// Custom Components
import ProductCard from "../components/ProductCard/ProductCard";
import CustomPagination from "../components/Pagination/Pagination";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";
import { getWatchTypes } from "../api/type";
import { getWatchCaliber } from "../api/caliber";
import { getWatchConditions } from "../api/condition";

export default function Catalogue() {
  const [products, setProducts] = useState(null);
  const [pageHeroBackground, setPageHeroBackground] = useState({});

  const alert = useContext(AlertContext);

  const { brands, fetchBrands } = useProducts();

  const [loading, setLoading] = useState(false);
  const [loadingWatchBands, setLoadingWatchBands] = useState(true);
  const [loadingWatchGenres, setLoadingWatchGenres] = useState(true);
  const [loadingWatchTypes, setLoadingWatchTypes] = useState(true);
  const [loadingWatchCalibers, setLoadingWatchCalibers] = useState(true);
  const [loadingWatchConditions, setLoadingWatchConditions] = useState(true);
  const [loadingHero, setLoadingHero] = useState(true);
  // const [brands, setBrands] = useState([]);
  const [watchBands, setWatchBands] = useState([]);
  const [watchGenres, setWatchGenres] = useState([]);
  const [watchTypes, setWatchTypes] = useState([]);
  const [watchCalibers, setWatchCalibers] = useState([]);
  const [watchConditions, setWatchConditions] = useState([]);

  const [originalProducts, setOriginalProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts = products?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      genero: {
        hombre: false,
        Mujer: false,
        unisex: false,
      },
      marca: {
        Bulova: false,
        Citizen: false,
        IWC: false,
        Longines: false,
        Mido: false,
        Omega: false,
        "Porsche Design": false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
      condicion: {
        nuevo: false,
        usado: false,
      },
    },
  };

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProducts2, setFilteredProducts2] = useState([]);
  const [testinState, setTestingState] = useState(state);
  const [category, setCategory] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const [categories, setCategories] = useState([]);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      genero: [],
      marca: [],
      tipo: [],
      condicion: [],
    };
    const { calibre, genero, correa, marca, tipo, tipoCorrea, condicion } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      // console.log(calibreKey);
      // console.log(calibre);
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let generoKey in genero) {
      // console.log(generoKey);
      // console.log(genero[generoKey]);
      if (genero[generoKey]) collectedTrueKeys.genero.push(generoKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      // console.log(tipoCorreaKey);
      // console.log(tipoCorrea[tipoCorreaKey]);
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let marcaKey in marca) {
      if (marca[marcaKey]) collectedTrueKeys.marca.push(marcaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    for (let condicionKey in condicion) {
      if (condicion[condicionKey])
        collectedTrueKeys.condicion.push(condicionKey);
    }
    // console.log(collectedTrueKeys);
    return collectedTrueKeys;
  };

  // console.log("filteredCollected", filteredCollected());

  // Resource: https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
  const multiPropsFilter = (originalProducts, filters) => {
    const filterKeys = Object.keys(filters);
    console.log(filterKeys);
    return originalProducts?.filter((product) => {
      return filterKeys.every((key) => {
        // console.log("key", key);
        if (!filters[key].length) return true;
        return filters[key].includes(product[key].nombre);
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(
      originalProducts,
      filteredCollected()
    );
    // console.log("filteredProducts2", filteredProducts2);
    return filteredProducts2;
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getProducts();
      if (response?.error) {
        console.error("Error fetching watch products:", response.message);
        alert.error(
          "Hubo un error al traer información de productos. Por favor, intenta más tarde."
        );
      } else {
        setProducts(response.docs);
        setOriginalProducts(response.docs);
        setTotalPages(response.totalDocs);
      }
      setLoading(false);
      // if (query.platform) {
      // }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getWatchGenres();
      if (response?.error) {
        console.error("Error fetching watch genres:", response.message);
        alert.error(
          "Hubo un error al traer información de géneros. Por favor, intenta más tarde."
        );
      } else {
        setWatchGenres(
          response.docs.map(
            ({ portada, createdAt, updatedAt, ...rest }) => rest
          )
        );
      }
      setLoadingWatchGenres(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getWatchBands();
      if (response?.error) {
        console.error("Error fetching watch bands:", response.message);
        alert.error(
          "Hubo un error al traer información de correas. Por favor, intenta más tarde."
        );
      } else {
        setWatchBands(
          response.docs.map(({ createdAt, updatedAt, ...rest }) => rest)
        );
      }
      setLoadingWatchBands(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getWatchTypes();
      if (response?.error) {
        console.error("Error fetching watch types:", response.message);
        alert.error(
          "Hubo un error al traer información de tipos. Por favor, intenta más tarde."
        );
      } else {
        setWatchTypes(
          response.docs.map(({ createdAt, updatedAt, ...rest }) => rest)
        );
      }

      setLoadingWatchTypes(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getWatchCaliber();
      // if (!response || response?.message) {
      //   throw new Error(response?.message || "Something went wrong.");
      // }
      // if (response !== null) {
      //   for (let index = 0; index < response.docs.length; index++) {
      //     delete response.docs[index].createdAt;
      //     delete response.docs[index].updatedAt;
      //   }
      //   setWatchCalibers(response.docs);
      //   setLoadingWatchCalibers(false);
      // }
      if (response?.error) {
        console.error("Error fetching watch calibers:", response.message);
        alert.error(
          "Hubo un error al traer información de calibres. Por favor, intenta más tarde."
        );
      } else {
        setWatchCalibers(
          response.docs.map(({ createdAt, updatedAt, ...rest }) => rest)
        );
      }
      setLoadingWatchCalibers(false);
      // const cleanedDocs = response.docs.map(({ createdAt, updatedAt, ...rest }) => rest);

      // setWatchCalibers(cleanedDocs);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const response = await getWatchConditions();
      if (response?.error) {
        console.error("Error fetching watch conditions:", response.message);
        alert.error(
          "Hubo un error al traer información de condiciones. Por favor, intenta más tarde."
        );
      } else {
        setWatchConditions(
          response.docs.map(({ createdAt, updatedAt, ...rest }) => rest)
        );
      }
      setLoadingWatchConditions(false);
    })();
  }, []);

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(".filters");

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };

  useEffect(() => {
    if (
      !loadingHero &&
      !loadingWatchBands &&
      !loadingWatchCalibers &&
      !loadingWatchConditions &&
      !loadingWatchGenres &&
      !loadingWatchTypes
    ) {
      initDropdownToggle();
    }
  }, [
    loadingHero,
    loadingWatchBands,
    loadingWatchCalibers,
    loadingWatchConditions,
    loadingWatchGenres,
    loadingWatchTypes,
  ]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getHeroCatalogue();
      if (response !== null) {
        // for (let index = 0; index < response.data.length; index++) {
        //   delete response.data[index].attributes.createdAt;
        //   delete response.data[index].attributes.publishedAt;
        //   delete response.data[index].attributes.updatedAt;
        // }
        setPageHeroBackground(response);
        setLoadingHero(false);
        // setLoading(false);
      } else {
        setLoadingHero(false);
      }
      // setLoading(false);
    })();
  }, []);

  /*---------- WORKING VERISON -----------*/
  const filterByReference = () => {
    let res = products;
    if (categories.length !== 0) {
      res = res.filter((el) => {
        return categories.find((element) => {
          return (
            element === el.genero.nombre.toLowerCase() ||
            element === el.tipoCorrea.nombre.toLowerCase() ||
            element === el.marca.nombre.toLowerCase() ||
            element === el.tipo.nombre.toLowerCase() ||
            element === el.calibre.nombre.toLowerCase()
          );
        });
      });
      setProducts(res);
      return;
    } else {
      setProducts(originalProducts);
    }
  };

  // const filterByReference2 = () => {
  //   // let res = products;
  //   // let productsMatch = [];
  //   // if (categories.length !== 0) {
  //   //   if (categories.length === 1) {
  //   //     categories.forEach((element) => {
  //   //       productsMatch = originalProducts.filter(
  //   //         (product) =>
  //   //           element ===
  //   //             product.attributes.genero.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.marca.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipo.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.calibre.data.attributes.nombre.toLowerCase()
  //   //       );
  //   //     });
  //   //   } else {
  //   //     categories.forEach((element) => {
  //   //       productsMatch = res.filter(
  //   //         (product) =>
  //   //           element ===
  //   //             product.attributes.genero.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.marca.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipo.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.calibre.data.attributes.nombre.toLowerCase()
  //   //       );
  //   //     });
  //   //   }
  //   //   console.log(productsMatch);
  //   //   setProducts(productsMatch);
  //   //   return;
  //   // } else {
  //   //   setProducts(originalProducts);
  //   // }
  //   // var data = [
  //   //   { firstName: "John", lastName: "Doe", age: 40 },
  //   //   { firstName: "Foo", lastName: "Bar", age: 46 },
  //   // ];
  //   // var keywords = ["Doe", 46];
  //   var map = {};

  //   if (categories.length > 0) {
  //     products?.forEach((x) => {
  //       let genero = x.attributes.genero.data.attributes.nombre.toLowerCase();
  //       let values = map[genero];
  //       if (!values) {
  //         values = [];
  //       }
  //       values.push(x);
  //       // console.log(map[genero]);
  //       map[genero] = values;

  //       let tipoCorrea =
  //         x.attributes.tipoCorrea.data.attributes.nombre.toLowerCase();
  //       let ageValues = map[tipoCorrea];
  //       if (!ageValues) {
  //         ageValues = [];
  //       }
  //       if (
  //         categories.every((i) =>
  //           categories.includes(
  //             x.attributes.genero.data.attributes.nombre.toLowerCase()
  //           )
  //         )
  //       ) {
  //         ageValues.push(x);
  //         map[tipoCorrea] = ageValues;
  //       }
  //       // console.log(map[genero], "map");
  //     });
  //     var result = [];
  //     categories.forEach((x) => {
  //       var tmpResult = map[x];
  //       if (tmpResult) {
  //         console.log("adding values");
  //         result.push(...tmpResult);
  //       }
  //     });
  //     // console.log(result, "result");
  //     setProducts(result);
  //   } else {
  //     setProducts(originalProducts);
  //   }
  // };

  // useEffect(() => {
  //   filterByReference2();
  //   // setCurrentsPosts(products?.slice(indexOfFirstPost, indexOfLastPost));
  // }, [categories.length]);

  // useEffect(() => {
  //   if (
  //     brands.data?.length > 0 &&
  //     watchBands.data?.length > 0 &&
  //     watchGenres.data?.length > 0
  //   ) {
  //     initFilters();
  //   }
  // }, [brands.data?.length, watchBands.data?.length, watchGenres.data?.length]);
  if (
    loadingHero &&
    loadingWatchBands &&
    loadingWatchCalibers &&
    loadingWatchConditions &&
    loadingWatchGenres &&
    loadingWatchTypes
  ) {
    return (
      <div className="catalogue">
        <Container>
          <p className="py-4">Cargando información...</p>
        </Container>
      </div>
    );
  }
  return (
    // <Container>
    //   <p>Hello this is catalogue</p>
    //   <div className="products">
    //     <div className="products__container">
    //       <Row>
    //         {products?.data.map((product, key) => (
    //           <ProductCard product={product} key={key} />
    //         ))}
    //       </Row>
    //     </div>
    //   </div>
    // </Container>
    <section className="catalogue">
      <div
        className="jumbotron"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URL}${pageHeroBackground?.imagen?.url})`,
          // backgroundImage: `url(${pageHeroBackground?.imagen.url})`,
        }}
      ></div>
      <Row>
        <section
          className="filters col-12 col-lg-3"
          // onClick={() => setExpanded(expanded ? false : "show")}
        >
          <div className="filters__container">
            <div className="filters__header">
              <img src={FilterIcon} alt="Filtros" className="filters__icon" />
              <p className="filters__nombre">Filtros</p>
              <span
                className="d-block d-lg-none ms-3"
                style={{ fontSize: ".7rem" }}
              >
                Haz clic para expandir filtros
              </span>
            </div>
            <div className="filters__filter-container">
              <div
                className="filters__filter filters__filter--clear filter__item"
                data-filter="all"
              >
                <div className="filters__filter__content">
                  <p
                    className="filters__filter__name filters__filter__name--small"
                    onClick={() => {
                      const selectedFilter = document.querySelectorAll(
                        ".filters__filter__dropdown-menu__item__name"
                      );
                      // const filtersDropdownMenu = document.querySelectorAll(
                      //   ".filters__filter__dropdown-menu"
                      // );
                      // const filtersDropdown = document.querySelectorAll(
                      //   ".filters__filter--dropdown"
                      // );
                      selectedFilter.forEach((filter) =>
                        filter.classList.remove("active")
                      );
                      // filtersDropdown.forEach((filter) =>
                      //   filter.classList.remove("open")
                      // );
                      // filtersDropdownMenu.forEach((filter) =>
                      //   filter.classList.remove("open")
                      // );
                      // selectedFilter.classList.remove("active");
                      // filtersDropdownMenu.classList.remove("show");
                      setFilteredProducts([]);
                      setCategories([]);
                      setCategory("");
                      setTestingState(state);
                    }}
                  >
                    Limpiar filtros <i className="far fa-times"></i>
                  </p>
                </div>
              </div>
              {/* <div
                className="filters__filter filters__filter--toggle filter__item"
                data-filter="discount"
              >
                <div className="filters__filter__content">
                  <p className="filters__filter__name filters__filter__name--small">
                    Con descuento
                  </p>
                  <div className="filters__toggle">
                    <input
                      type="checkbox"
                      className="filters__toggle__checkbox"
                      id="filters__toggle--discount"
                    />
                    <label
                      htmlFor="filters__toggle--discount"
                      className="filters__toggle__btn"
                    ></label>
                  </div>
                </div>
              </div> */}
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Género</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {watchGenres?.map((watchGenre, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={watchGenre.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            // console.log(e.target);
                            allFilterClickListener(watchGenre.nombre, "genero");
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");
                            if (
                              categories.includes(
                                watchGenre.nombre.toLowerCase()
                              )
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !== watchGenre.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                watchGenre.nombre.toLowerCase(),
                              ]);
                            }
                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterGenreFunction(watchGenre.attributes.nombre);
                            //   setCategory(watchGenre.attributes.nombre);
                            // } else {
                            //   filterGenreFunction([]);
                            //   setCategory("");
                            // }
                            // console.log(
                            //   products?.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === watchGenre.attributes.nombre
                            //   )
                            // );
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {watchGenre.nombre}
                          </p>
                        </li>
                      ))}
                      {/* <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="hombre"
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Hombre
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="mujer"
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Mujer
                        </p>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Correa</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {watchBands?.map((watchBand, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={watchBand.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            allFilterClickListener(
                              watchBand.nombre,
                              // .split(" ")
                              // .join("_"),
                              "tipoCorrea"
                            );
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            // const filterMenu =
                            //   document.querySelector(".filter__item");
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");
                            // setProducts(
                            //   products?.data.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === watchBand.attributes.nombre
                            //   )
                            // );
                            if (
                              categories.includes(
                                watchBand.nombre.toLowerCase()
                              )
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !== watchBand.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                watchBand.nombre.toLowerCase(),
                              ]);
                            }
                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterFunction(watchBand.attributes.nombre);
                            //   setCategory(watchBand.attributes.nombre);
                            // } else {
                            //   setFilteredProducts([]);
                            //   setCategory("");
                            // }
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {watchBand.nombre.toLowerCase()}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Marca</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {brands?.map((brand, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={brand.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            // console.log(e.target);
                            allFilterClickListener(
                              brand.nombre,
                              // .toLowerCase()
                              // .split(" ")
                              // .join("_"),
                              "marca"
                            );
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");

                            if (
                              categories.includes(brand.nombre.toLowerCase())
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !== brand.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                brand.nombre.toLowerCase(),
                              ]);
                            }

                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterBrandFunction(brand.attributes.nombre);
                            //   setCategory(brand.attributes.nombre);
                            // } else {
                            //   filterGenreFunction([]);
                            //   setCategory("");
                            // }
                            // console.log(
                            //   products?.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === brand.attributes.nombre
                            //   )
                            // );
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {brand.nombre}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Condición</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {watchConditions?.map((condition, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={condition.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            // console.log(e.target);
                            allFilterClickListener(
                              condition.nombre,
                              // .toLowerCase()
                              // .split(" ")
                              // .join("_"),
                              "condicion"
                            );
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");

                            if (
                              categories.includes(
                                condition.nombre.toLowerCase()
                              )
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !== condition.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                condition.nombre.toLowerCase(),
                              ]);
                            }

                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterBrandFunction(brand.attributes.nombre);
                            //   setCategory(brand.attributes.nombre);
                            // } else {
                            //   filterGenreFunction([]);
                            //   setCategory("");
                            // }
                            // console.log(
                            //   products?.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === brand.attributes.nombre
                            //   )
                            // );
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {condition.nombre}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Tipo</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {/* <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="japones"
                        onClick={(e) => {
                          allFilterClickListener("Japonés", "tipo");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("japonés")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "japonés"
                              )
                            );
                          } else {
                            setCategories([...categories, "japonés"]);
                          }

                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterTypeFunction("japonés");
                          //   setCategory("japones");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Japonés
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="suizo"
                        onClick={(e) => {
                          allFilterClickListener("Suizo", "tipo");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("suizo")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "suizo"
                              )
                            );
                          } else {
                            setCategories([...categories, "suizo"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterFunction("suizo");
                          //   setCategory("suizo");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Suizo
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="vintage"
                        onClick={(e) => {
                          allFilterClickListener("Vintage", "tipo");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("vintage")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "vintage"
                              )
                            );
                          } else {
                            setCategories([...categories, "vintage"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterFunction("vintage");
                          //   setCategory("vintage");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Vintage
                        </p>
                      </li> */}
                      {watchTypes?.map((type, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={type.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            allFilterClickListener(type.nombre, "tipo");
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");

                            if (
                              categories.includes(type.nombre.toLowerCase())
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !== type.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                type.nombre.toLowerCase(),
                              ]);
                            }
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {type.nombre}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Calibre</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {/* <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="automático"
                        onClick={(e) => {
                          allFilterClickListener("Automático", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("automático")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "automático"
                              )
                            );
                          } else {
                            setCategories([...categories, "automático"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filtersDropdownMenu.classList.remove("active");
                          //   filterCaliberFunction("automático");
                          //   setCategory("automático");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Automático
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="cuarzo"
                        onClick={(e) => {
                          allFilterClickListener("Cuarzo", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("cuarzo")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "cuarzo"
                              )
                            );
                          } else {
                            setCategories([...categories, "cuarzo"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterCaliberFunction("cuarzo");
                          //   setCategory("cuarzo");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Cuarzo
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="cuerda"
                        onClick={(e) => {
                          allFilterClickListener("Cuerda", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("cuerda")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "cuerda"
                              )
                            );
                          } else {
                            setCategories([...categories, "cuerda"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterCaliberFunction("cuerda");
                          //   setCategory("cuerda");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Cuerda
                        </p>
                      </li> */}
                      {watchCalibers?.map((type, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={type.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            allFilterClickListener(type.nombre, "calibre");
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");

                            if (
                              categories.includes(type.nombre.toLowerCase())
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !== type.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                type.nombre.toLowerCase(),
                              ]);
                            }
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {type.nombre}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="products col-12 col-lg-9">
          <div className="products__container">
            <div className="products__header">
              <h2 className="products__nombre">Nuestra colección</h2>
              <p className="products__text">
                Disfruta de nuestros ejemplares de los cuales encontrarás una
                excelente calidad entre ellos relojes nuevos, usados, suizos,
                japonenes, vintage, con calibres automáticos, de cuarzo y de
                cuerda. Todos 100% originales, verificados previamente por
                personal capacitado. Y con los mejores precios en el mercado.
              </p>
            </div>
            <div className="products__product-container">
              {/* {!loading && data.allProductos?.length > 0 ? (
              data.allProductos.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))
            ) : (
              <p className="products__emtpy">Cargando productos...</p>
            )} */}
              <Row>
                {/* {filteredProducts?.length > 0 &&
                  filteredProducts?.map((product, key) => (
                    <ProductCard product={product} key={key} />
                  ))} */}

                {/* {filteredProducts?.length === 0 && categories.length === 0 && (
                  <p className="pt-3">No hay productos</p>
                )} */}
                {/* {filteredProducts?.length < 1 && (products?.length > 0 && (
                  products?.map((product, key) => (
                    <ProductCard product={product} key={key} />
                  ))}) */}

                {loading ? (
                  <p className="pt-3">Cargando productos...</p>
                ) : currentPosts?.length > 0 && categories.length === 0 ? (
                  currentPosts?.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))
                ) : null}

                {!loading && categories.length > 0
                  ? searchProducts()
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((product, key) => (
                        <ProductCard
                          product={product}
                          key={key}
                          loading={loading}
                        />
                      ))
                  : null}
                {!loading &&
                categories.length > 0 &&
                searchProducts().length === 0 ? (
                  <p className="py-3">
                    No se encontraron relojes con los filtros seleccionados.
                  </p>
                ) : null}

                {/* {filteredProducts?.length == 0 &&
                  categories?.length === 0 &&
                  currentPosts?.length > 0 &&
                  currentPosts?.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))} */}
              </Row>
              {/* {!loading && (
                <CustomPagination
                  postsPerPage={postsPerPage}
                  totalPosts={originalProducts.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              )} */}
              {categories.length !== 0 ? (
                <CustomPagination
                  postsPerPage={postsPerPage}
                  totalPosts={searchProducts()?.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              ) : (
                <CustomPagination
                  postsPerPage={postsPerPage}
                  totalPosts={totalPages}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              )}
            </div>
          </div>
        </section>
      </Row>
    </section>
  );
}
