import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Context
import AlertContext from "../context/AlertContext";

// Form validation
import { Formik } from "formik";
import * as yup from "yup";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

// Custom CSS
import "../assets/scss/pages/signin.scss";

import { loginApi } from "../api/user";
import useAuth from "../hooks/useAuth";

const initialValues = {
  email: "",
  password: "",
};

let schema = yup.object().shape({
  email: yup.string().email().required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

export default function Login() {
  const { login } = useAuth();
  const history = useHistory();
  const [error, setError] = useState();

  const alert = useContext(AlertContext);
  return (
    <div className="sign-in">
      <Container className="sign-in__container">
        <h2>Inicia sesión con tu cuenta</h2>
        <p className="mt-2">
          Ingresa tu correo electrónico y contraseña que utilizaste al momento
          de tu registro registro
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            // }, 400);
            const response = await loginApi(values);
            if (response?.token) {
              login(response.token);
              alert.success("Ingreso de sesión exitoso");
              history.push("/catalogue");
            } else {
              alert.error("El email o la contraseña son incorrectos");
              setError("El email o la contraseña son incorrectos");
              console.error("El email o la contraseña son incorrectos");
            }
            // const response = await registerApi(values);
            // if (response?.jwt) {
            //   console.log(response);
            // } else {
            //   console.error(
            //     "Error al registrar el usuario, inténtelo mas tarde"
            //   );
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-lg-3">
                <Form.Group
                  className="mb-3 form-group"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={errors.email}
                  />
                  <div className="error-feedback">
                    {errors.email && touched.email && errors.email}
                  </div>
                  <Form.Text className="text-muted">
                    Utiliza el email que usaste para tu registro
                  </Form.Text>
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    onChange={handleChange}
                    isInvalid={errors.password}
                    placeholder="Contraseña"
                  />
                  <div className="error-feedback">
                    {errors.password && touched.password && errors.password}
                  </div>
                </Form.Group>
                <Link className="forgot-password" to="/forgot-password">
                  ¿Olvidaste tu contraseña?
                </Link>
                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
              </Row>
              {/* {error} */}
              <Button
                variant="primary"
                type="submit"
                className="product__cta product__cta--buy"
                disabled={isSubmitting}
              >
                Iniciar sesión
              </Button>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <Link className="register" to="/register">
            ¿No tienes cuenta? Regístrate
          </Link>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    </div>
  );
}
