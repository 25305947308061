import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Context
import { useProducts } from "../context/ProductsContext";

// Custom components
import ProductCard from "../components/ProductCard/ProductCard";

// API
import { getConditionProducts, getBrandProducts } from "../api/product";
import { getCondition, getWatchGenres } from "../api/genre";
import { getBrands } from "../api/brand";
import { getWatchBands } from "../api/watch-band";
import { getWatchTypes } from "../api/type";
import { getWatchCaliber } from "../api/caliber";

// Util
import { toggleClass } from "../utils/toggleClass";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";

// Custom styles
import "../assets/scss/pages/condition.scss";

function Condition() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [condition, setCondition] = useState(null);
  const [loadingCondition, setLoadingCondition] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState("");

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(
      ".condition__catalogue__filters"
    );

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };

  useEffect(() => {
    (async () => {
      const response = await getConditionProducts(slug);
      if (response !== null) {
        setProducts(response.docs);
        setLoading(false);
      }
      setLoading(false);
    })(slug);
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getCondition(slug);
      if (response !== null) {
        setCondition(response.docs[0]);
        setLoadingCondition(false);
      }
      setLoadingCondition(false);
    })(slug);
  }, [slug]);

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      genero: {
        hombre: false,
        Mujer: false,
        unisex: false,
      },
      marca: {
        Bulova: false,
        Citizen: false,
        IWC: false,
        Longines: false,
        Mido: false,
        Omega: false,
        "Porsche Design": false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
    },
  };

  const [testinState, setTestingState] = useState(state);
  const [categories, setCategories] = useState([]);
  const [watchBands, setWatchBands] = useState([]);
  const [loadingWatchBands, setLoadingWatchBands] = useState(true);
  const [watchGenres, setWatchGenres] = useState([]);
  const [loadingWatchGenres, setLoadingWatchGenres] = useState(true);
  const [brands, setBrands] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [watchTypes, setWatchTypes] = useState([]);
  const [loadingWatchTypes, setLoadingWatchTypes] = useState(true);
  const [watchCalibers, setWatchCalibers] = useState([]);
  const [loadingWatchCalibers, setLoadingWatchCalibers] = useState(true);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      genero: [],
      marca: [],
      tipo: [],
    };
    const { calibre, genero, correa, marca, tipo, tipoCorrea } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let generoKey in genero) {
      if (genero[generoKey]) collectedTrueKeys.genero.push(generoKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let marcaKey in marca) {
      if (marca[marcaKey]) collectedTrueKeys.marca.push(marcaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    return collectedTrueKeys;
  };

  const multiPropsFilter = (originalProducts, filters) => {
    const filterKeys = Object.keys(filters);
    // console.log(filterKeys);
    return originalProducts?.filter((product) => {
      return filterKeys.every((key) => {
        // console.log("key", key);
        if (!filters[key].length) return true;
        return filters[key].includes(product[key].nombre);
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(products, filteredCollected());
    return filteredProducts2;
  };

  useEffect(() => {
    (async () => {
      const response = await getWatchGenres();
      if (response !== null) {
        setWatchGenres(response.docs);
        setLoadingWatchGenres(false);
      }
      setLoadingWatchGenres(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getWatchBands();
      if (response !== null) {
        setWatchBands(response.docs);
        setLoadingWatchBands(false);
      }
      setLoadingWatchBands(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getBrands();
      if (response !== null) {
        setBrands(response.docs);
        setLoadingBrands(false);
      }
      setLoadingBrands(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const response = await getWatchTypes();
      if (response !== null) {
        setWatchTypes(response.docs);
        setLoadingWatchTypes(false);
      }
      setLoadingWatchTypes(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const response = await getWatchCaliber();
      if (response !== null) {
        setWatchCalibers(response.docs);
        setLoadingWatchCalibers(false);
      }
      setLoadingWatchCalibers(false);
    })();
  }, [slug]);

  useEffect(() => {
    if (!loading && !loadingWatchBands && !loadingWatchGenres) {
      initDropdownToggle();
    }
  }, [loading, loadingWatchBands, loadingWatchGenres]);

  if (loading || loadingWatchBands || loadingWatchGenres) {
    return (
      <section className="brand-catalogue">
        <Container>
          <p className="py-4">Cargando...</p>
        </Container>
      </section>
    );
  }

  return (
    <section className="condition__catalogue">
      <div
        className="products__genre__header"
        style={{
          backgroundImage: `linear-gradient(
                   rgba(0, 0, 0, 0.53),
                   rgba(0, 0, 0, 0.275)
                 ), url(${process.env.REACT_APP_API_URL}${condition?.portada?.url})`,
        }}
      >
        <h3 className="text-capitalize">{condition?.nombre}</h3>
      </div>
      <div className="condition__catalogue__products">
        <Container fluid>
          <Row>
            <div
              className="condition__catalogue__filters col-12 col-lg-3"
              // onClick={() => setExpanded(expanded ? false : "show")}
            >
              <div className="filters__container">
                <div className="filters__header">
                  <img
                    src={FilterIcon}
                    alt="Filtros"
                    className="filters__icon"
                  />
                  <p className="filters__title">Filtros</p>
                  <span
                    className="d-block d-lg-none ms-3"
                    style={{ fontSize: ".7rem" }}
                  >
                    Haz clic para expandir filtros
                  </span>
                </div>
                <div className="filters__filter-container">
                  <div
                    className="filters__filter filters__filter--clear filter__item"
                    data-filter="all"
                  >
                    <div className="filters__filter__content">
                      <p
                        className="filters__filter__name filters__filter__name--small"
                        onClick={() => {
                          const selectedFilter = document.querySelectorAll(
                            ".filters__filter__dropdown-menu__item__name"
                          );
                          selectedFilter.forEach((filter) =>
                            filter.classList.remove("active")
                          );
                          setFilteredProducts([]);
                          setCategories([]);
                          setTestingState(state);
                        }}
                      >
                        Limpiar filtros <i className="far fa-times"></i>
                      </p>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Correa</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {watchBands?.map((watchBand, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={watchBand.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                allFilterClickListener(
                                  watchBand.nombre,
                                  "tipoCorrea"
                                );
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                // if (
                                //   filteredProducts.length === 0 &&
                                //   category === ""
                                // ) {
                                //   filterFunction(watchBand.nombre);
                                //   setCategory(watchBand.nombre);
                                // } else {
                                //   setFilteredProducts([]);
                                //   setCategory("");
                                // }
                                if (
                                  categories.includes(
                                    watchBand.nombre.toLowerCase()
                                  )
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !==
                                        watchBand.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    watchBand.nombre.toLowerCase(),
                                  ]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name text-capitalize">
                                {watchBand.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Género</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {watchGenres?.map((watchGenre, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={watchGenre.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                allFilterClickListener(
                                  watchGenre.nombre,
                                  "genero"
                                );
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");

                                // if (
                                //   filteredProducts.length === 0 &&
                                //   category === ""
                                // ) {
                                //   filterGenreFunction(
                                //     watchGenre.nombre
                                //   );
                                //   setCategory(watchGenre.nombre);
                                // } else {
                                //   filterGenreFunction([]);
                                //   setCategory("");
                                // }
                                if (
                                  categories.includes(
                                    watchGenre.nombre.toLowerCase()
                                  )
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !==
                                        watchGenre.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    watchGenre.nombre.toLowerCase(),
                                  ]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name text-capitalize">
                                {watchGenre.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Marca</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {brands?.map((brand, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={brand.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                // console.log(e.target);
                                allFilterClickListener(
                                  brand.nombre,
                                  // .toLowerCase()
                                  // .split(" ")
                                  // .join("_"),
                                  "marca"
                                );
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");

                                if (
                                  categories.includes(
                                    brand.nombre.toLowerCase()
                                  )
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !== brand.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    brand.nombre.toLowerCase(),
                                  ]);
                                }

                                // if (
                                //   filteredProducts.length === 0 &&
                                //   category === ""
                                // ) {
                                //   filterBrandFunction(brand.attributes.nombre);
                                //   setCategory(brand.attributes.nombre);
                                // } else {
                                //   filterGenreFunction([]);
                                //   setCategory("");
                                // }
                                // console.log(
                                //   products?.filter(
                                //     (product) =>
                                //       product.attributes.tipoCorrea.data.attributes
                                //         .nombre === brand.attributes.nombre
                                //   )
                                // );
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                {brand.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Tipo</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {watchTypes.map((type, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={type.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                allFilterClickListener(type.nombre, "tipo");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");

                                if (
                                  categories.includes(type.nombre.toLowerCase())
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !== type.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    type.nombre.toLowerCase(),
                                  ]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                {type.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Calibre</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {watchCalibers.map((type, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={type.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                allFilterClickListener(type.nombre, "calibre");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");

                                if (
                                  categories.includes(type.nombre.toLowerCase())
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !== type.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    type.nombre.toLowerCase(),
                                  ]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                {type.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <Row>
                {!loading &&
                  categories.length > 0 &&
                  searchProducts().map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))}
                {!loading &&
                categories.length > 0 &&
                searchProducts().length === 0 ? (
                  <p className="py-3">
                    No se encontraron relojes con los filtros seleccionados.
                  </p>
                ) : null}
                {products?.length === 0 && categories.length === 0 && (
                  <div className="col-12 col-lg-9">
                    <p className="pt-3">No hay productos</p>
                  </div>
                )}
                {filteredProducts?.length === 0 &&
                  categories.length === 0 &&
                  products?.length > 0 &&
                  products?.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))}
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Condition;
