import React, { useState, useEffect, useRef, Fragment } from "react";

// Context
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

const SessionTimeout = () => {
  const { logout } = useAuth();

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [timerId, setTimerId] = useState(false);
  const renderCount = useRef(1);

  const history = useHistory();

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });

  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === "hidden") {
        // set timer to log user out
        const id = window.setTimeout(() => {
          setIsLoggedOut(true);
          logout();
          history.push("/catalogue");
          // console.log("se cerró sesión");
        }, 3600000);
        setTimerId(id);
      } else {
        // clear existing timer
        window.clearTimeout(timerId);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => document.removeEventListener("visibilitychange", autoLogout);
  }, [timerId]);
  return <Fragment />;
};

export default SessionTimeout;
