import { getToken, hasExpiredToken } from "../api/token";

export async function authFetch(url, params, logout) {
  const token = getToken();

  if (!token) {
    // Usuario no logeado
    logout();
  } else {
    if (hasExpiredToken(token)) {
      // Token caducado
      logout();
    } else {
      const paramsTemp = {
        ...params,
        headers: {
          ...params?.headers,
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await fetch(url, paramsTemp);
        // console.log(response, "34");
        if (!response.ok) {
          const errorData = await response.json().catch(() => null); // Try to parse response JSON
          throw {
            error: true,
            errorCode: response.status,
            message:
              errorData?.message ||
              `Unexpected error occurred (Status: ${response.status})`,
          };
        }
        const result = await response.json();
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  }
}
