import { BASE_PATH } from "../utils/constants";

export async function getWatchTypes() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/tipos`;
    const response = await fetch(url);
    if (!response.ok) {
      // Throw an error with the status code and message
      return {
        error: true,
        message: `Error in calibres ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
