import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// Form validation
import { Formik } from "formik";
import * as yup from "yup";

// API
import { getComments, postComment } from "../api/comments";
import { getMeApi } from "../api/user";
import useAuth from "../hooks/useAuth";

// Context
import AlertContext from "../context/AlertContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Custom CSS
import "../assets/scss/pages/reviews.scss";

// Assets
import starIcon from "../assets/images/star-icon.svg";

let schema = yup.object().shape({
  user: yup.string().required("Este campo es requerido"),
  review: yup.string().required("Este campo es requerido"),
  score: yup
    .number()
    .required("Este campo es requerido")
    .moreThan(1, "La calificación debe ser mayor a 1")
    .lessThan(6, "La calificación máxima es 5"),
});

export default function Comments() {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPostComment, setLoadingPostComment] = useState(false);
  // const [user, setUser] = useState(undefined);
  const { auth, logout, user } = useAuth();

  const alert = useContext(AlertContext);

  useEffect(() => {
    (async () => {
      const response = await getComments();
      if (response.error) {
        alert.error("Hubo un error al traer información comentarios.");
        setComments([]);
      } else {
        setComments(response.docs);
      }
      setLoading(false);
    })();
  }, []);
  // console.log(comments);

  // useEffect(() => {
  //   (async () => {
  //     const response = await getMeApi(logout);
  //     if (response) {
  //       setUser(response);
  //     }
  //   })(logout);
  // }, [auth]);
  // console.log(user);
  // console.log(auth);

  if (loading) {
    return (
      <div className="raffles__wrapper">
        <Container className="text-center">
          <p className="py-4">Cargando comentarios ⌛️...</p>
        </Container>
      </div>
    );
  }

  return (
    <div className="reviews__wrapper">
      <div className="reviews__header">
        <Container>
          <h3>Opiniones de usuarios</h3>
        </Container>
      </div>
      <div className="reviews__container">
        <Container>
          <p>
            Comparte la experiencia que tuviste con nosotros al comprarnos.
            Estamos para servirte y tu opinión es importante para cada día
            mejorar.
          </p>
          <div>
            {comments?.map((comment, key) => (
              <div className="reviews__item" key={key}>
                <h4>{comment.usuario}</h4>
                <div className="d-flex align-items-center my-2">
                  <img src={starIcon} alt="La Relojeríair" />{" "}
                  <h5>{comment.calificacion}</h5>
                </div>
                <h6>Comentario:</h6>
                <p>"{comment.comentario}"</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div className="reviews__input">
        {Object.keys(user).length > 0 ? (
          <Container className="reviews__input__container">
            <p>Llena el siguiente formulario para publicar una reseña:</p>
            <Formik
              initialValues={{
                user: user.user.fullName || "",
                review: "",
                score: 5,
              }}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // setTimeout(() => {
                //   alert(JSON.stringify(values, null, 2));
                //   setSubmitting(false);
                // }, 400);
                setLoadingPostComment(true);
                const formDataTemp = {
                  // usuario: auth.idUser,
                  usuario: user.user.id,
                  comentario: values.review,
                  user: auth.idUser,
                  calificacion: values.score,
                };
                const response = await postComment(formDataTemp, logout);
                if (!response) {
                  alert.error(
                    "Hubo un error en la publicación de tu comentario."
                  );
                  // console.log(
                  //   "Hubo un error en la publicación de tu comentario"
                  // );
                } else {
                  // console.log(response);
                  alert.success("Tu reseña ha sido publicada exitosamente.");
                  const { usuario, ...rest } = response.doc; // Destructure 'usuario' and the rest of the properties from response.doc
                  const updatedDoc = { ...rest, usuario: usuario.fullName };
                  if (comments?.length > 0) {
                    setComments([...comments, updatedDoc]);
                  } else {
                    // console.log(response.doc);
                    setComments([...comments, updatedDoc]);
                  }
                  resetForm({
                    user: user.user.fullName,
                    review: "",
                    score: 5,
                  });
                }
                setLoadingPostComment(false);
                // const response = await loginApi(values);
                // if (response?.jwt) {
                //   login(response.jwt);
                //   history.push("/catalogue");
                // } else {
                //   console.error("El email o la contraseña son incorrectos");
                // }
                // const response = await registerApi(values);
                // if (response?.jwt) {
                //   console.log(response);
                // } else {
                //   console.error(
                //     "Error al registrar el usuario, inténtelo mas tarde"
                //   );
                // }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      xs="12"
                      lg="6"
                      className="mb-3"
                      controlId="formName"
                    >
                      <Form.Label>Nombre de usuario:</Form.Label>
                      <Form.Control
                        name="user"
                        onBlur={handleBlur}
                        value={user.user.fullName}
                        isInvalid={errors.user}
                        placeholder="Tu nombre"
                        readOnly
                        disabled={true}
                      />
                      <div className="error-feedback">
                        {errors.user && touched.user && errors.user}
                      </div>
                      <Form.Text className="text-muted">
                        Este es tu nombre de usuario
                      </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} xs="12" lg="6" className="mb-3">
                      <Form.Label>Calificación:</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="1.0"
                        step="0.5"
                        className="form-control"
                        name="score"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.score}
                        isInvalid={errors.score}
                      />
                      <div className="error-feedback">
                        {errors.score && touched.score && errors.score}
                      </div>
                      <Form.Text className="text-muted">Rango: 1-5</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formReview">
                      <Form.Label>Comentarios:</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Escribe tus comentarios aquí"
                        style={{ height: "100px" }}
                        name="review"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.review}
                        isInvalid={errors.review}
                      />
                      <div className="error-feedback">
                        {errors.review && touched.review && errors.review}
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
                  </Row>

                  <Button type="submit" disabled={isSubmitting}>
                    Enviar
                  </Button>
                </Form>
              )}
            </Formik>
          </Container>
        ) : (
          <Container className="py-5 text-center">
            <p>
              Debes iniciar sesión o registrarte para publicar un comentario
            </p>
            <Link to="/login">Iniciar sesión</Link>
          </Container>
        )}
      </div>
    </div>
  );
}
