import React, { useState, useEffect, useContext } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// API
import { getRafflesProducts, getRafflesHero } from "../api/bids";

// Custom Components
import BidProduct from "../components/BidProduct/BidProduct";

// Context
import AlertContext from "../context/AlertContext";

// Custom CSS
import "../assets/scss/pages/raffles.scss";

function Raffles() {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [pageHeroBackground, setPageHeroBackground] = useState(null);
  const [loadingHeroBackground, setLoadingHeroBackground] = useState(true);

  const alert = useContext(AlertContext);

  useEffect(() => {
    (async () => {
      const response = await getRafflesProducts();
      if (response.error) {
        alert.error("Hubo un error al traer información de productos.");
      } else {
        setProducts(response.docs);
      }
      setLoading(false);
      // if (query.platform) {
      // }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getRafflesHero();
      // console.log(response);
      if (response.error) {
        alert.error("Hubo un error al traer información de portada.");
      } else {
        setPageHeroBackground(response);
      }
      setLoadingHeroBackground(false);
      // if (query.platform) {
      // }
    })();
  }, []);

  if (loading || loadingHeroBackground) {
    return (
      <div className="raffles__wrapper">
        <Container>
          <p className="py-4">Cargando información...</p>
        </Container>
      </div>
    );
  }
  return (
    <section className="raffles__wrapper">
      <div
        className="jumbotron"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URL}${pageHeroBackground?.imagen?.url})`,
        }}
      ></div>
      <div className="raffles__header">
        <Container>
          <h3>Rifas</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem iure,
            delectus quis minima voluptas dolore consequuntur inventore
            molestias obcaecati iste illum voluptates, dolor pariatur ab esse
            velit? Velit vitae quasi, rerum veniam est excepturi nisi ea
            asperiores dolorum culpa ratione.
          </p>
        </Container>
      </div>
      <div className="raffles__container">
        <Container>
          <Row>
            {products?.map((product, key) => (
              <BidProduct key={key} product={product} />
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Raffles;
