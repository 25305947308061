import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Axios for sending email
import axios from "axios";

// Bootstrap components
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

// Custom CSS
// import "../../assets/scss/pages/payment.scss";
import "./paymentForm.styles.scss";

// Custom components
import OrderSummary from "../OrderSummary/OrderSummary";
import CardSection from "../CardSection/CardSection";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// API
import { getMeApi } from "../../api/user";
import { createOrder } from "../../api/order";
import { getProductsCart } from "../../api/cart";

// Hooks
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";

// Utils
import { v4 as uuidv4 } from "uuid";
import formatMoney from "../../utils/formatMoney";
import { BASE_PATH } from "../../utils/constants";

// Assets
import lockIconUrl from "../../assets/images/lock-icon.svg";
import creditCard1Url from "../../assets/images/credit-cards_visa.svg";
import creditCard2Url from "../../assets/images/credit-cards_mastercard.svg";
import creditCard3Url from "../../assets/images/credit-cards_amex.svg";

const initialValues = {
  clientName: "",
  // lastName: "",
  clientEmail: "",
  clientPhoneNumber: "",
  address: "",
  streetNumber: "",
  neighborhood: "",
  postalCode: 0,
  reference: "",
  municipality: "",
  city: "",
  state: "",
};

let schema = yup.object().shape({
  clientName: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  clientEmail: yup
    .string()
    .email("El email no es válido")
    .required("Este campo es requerido"),
  // .isValid("El email no es válido"),
  clientPhoneNumber: yup.number().required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  streetNumber: yup.string().required("Este campo es requerido"),
  neighborhood: yup.string().required("Este campo es requerido"),
  reference: yup.string().required("Este campo es requerido"),
  postalCode: yup
    .number()
    .required("Este campo es requerido")
    .moreThan(10000, "Ingresa un Código Postal válido"),
  municipality: yup.string().required("Este campo es requerido"),
  city: yup.string().required("Este campo es requerido"),
  state: yup.string().required("Este campo es requerido"),
});

export default function PaymentForm() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingOrderCreation, setLoadingOrderCreation] = useState(false);
  // const [cartItems, setCartItems] = useState([]);
  const { auth, logout } = useAuth();
  const {
    productsCart,
    resetCart,
    totalPriceCart,
    cartItems,
    totalProductsCart,
  } = useCart();
  const [shipment, setShipment] = useState(0);
  // const [error, setError] = useState("");
  const [formError, setFormError] = useState();
  const history = useHistory();
  // console.log(total);
  // useEffect(
  //   (logout) => {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       setUser(response);
  //     })();
  //   },
  //   [auth]
  // );
  // console.log(cartItems);
  // console.log(auth);

  // useEffect(() => {
  //   (async () => {
  //     if (user !== undefined) {
  //       const response = await getProductsCart(user?.id, logout);
  //       if (response?.data.length > 0) {
  //         // for (let index = 0; index < response.data.length; index++) {
  //         //   delete response.data[index].attributes.updatedAt;
  //         //   delete response.data[index].attributes.publishedAt;
  //         //   delete response.data[index].attributes.productos.data[index]
  //         //     .attributes.imagenes;
  //         // }
  //         setCartItems(response.data);
  //       } else setCartItems([]);
  //     }
  //   })();
  // }, [user]);
  const stripe = useStripe();
  const elements = useElements();
  return (
    <Row>
      <Col xs="12" lg="8" className="client-data">
        <div className="client-data__address mt-4">
          <h5>
            <span>1</span> Datos de envío
          </h5>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              const today = new Date();
              const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
              const orderId = uuidv4().slice(0, 7);
              setLoading(true);

              try {
                const { error, paymentMethod } =
                  await stripe.createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardElement),
                    billing_details: {
                      email: values.clientEmail,
                      name: values.clientName,
                      phone: values.clientPhoneNumber,
                      address: {
                        city: values.city,
                        country: "MX",
                        line1: values.address,
                        line2: values.neighborhood,
                        state: values.state,
                      },
                    },
                  });
                // console.log(paymentMethod);
                if (error) {
                  setFormError(
                    "Hubo un error con tu tarjeta. Verifica los datos y vuelve a intentarlo."
                  );
                  return; // Stop further execution
                }
                setLoading(false);
                const orderItemsFormatted = cartItems.map((item) => ({
                  product: item.product.id, // Send only product ID
                  quantity: item.quantity, // Send quantity
                }));
                const formDataTemp = {
                  // token: "22333fwefwefwefwe",
                  token: paymentMethod.id,
                  orderId,
                  clientName: values.clientName,
                  clientEmail: values.clientEmail,
                  clientPhoneNumber: values.clientPhoneNumber,
                  address: values.address,
                  streetNumber: values.streetNumber,
                  neighborhood: values.neighborhood,
                  postalCode: parseInt(values.postalCode),
                  reference: values.reference,
                  municipality: values.municipality,
                  city: values.city,
                  state: values.state,
                  country: "México",
                  total: totalPriceCart,
                  orderItems: cartItems.map((item) => ({
                    product: {
                      id: item.product.id,
                      nombre: item.product.nombre,
                      precio: item.product.precio,
                      eta: item.product.eta,
                    }, // adjust according to your cart item structure
                    quantity: item.quantity,
                  })),
                  usuario: auth.idUser,
                };
                setLoadingOrderCreation(true);
                const response = await createOrder(formDataTemp, auth);
                // console.log(response, "response");
                if (response.error && response.errorCode !== 400) {
                  setFormError(
                    "Hubo un error en tu método de pago. Verifica que los datos sean correctos o comunícate con tu institución bancaria."
                  );
                } else if (response.errorCode === 400) {
                  setFormError(
                    "Uno o más productos que deseas comprar ya se encuentran fuera de stock. No se te hizo ningún cargo."
                  );
                } else {
                  for (let i = 0; i < cartItems.length; i++) {
                    const element = cartItems[i];
                    resetCart(element.id, auth);
                    // console.log(response);
                    // return response;
                  }
                  history.push(`/confirmation/${response.doc.orderId}`);
                }
              } catch (error) {
                setFormError(
                  "Hubo un error en servidor. Por favor, vuelve a intentarlo"
                );
              } finally {
                setLoading(false);
                setLoadingOrderCreation(false);
              }

              // const response = await registerApi(values);
              // if (response?.jwt) {
              //   console.log(response);
              //   history.push("/login");
              // } else {
              //   console.error(
              //     "Error al registrar el usuario, inténtelo mas tarde"
              //   );
              // }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Nombre Completo:</Form.Label>
                    <Form.Control
                      name="clientName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientName}
                      isInvalid={errors.clientName}
                      disabled={isSubmitting}
                      placeholder="Tu nombre"
                    />
                    <div className="error-feedback">
                      {errors.clientName &&
                        touched.clientName &&
                        errors.clientName}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridEmail"
                  >
                    <Form.Label>Correo electrónico:</Form.Label>
                    <Form.Control
                      name="clientEmail"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientEmail}
                      isInvalid={errors.clientEmail}
                      disabled={isSubmitting}
                      placeholder="Email"
                    />
                    <div className="error-feedback">
                      {errors.clientEmail &&
                        touched.clientEmail &&
                        errors.clientEmail}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridPhone"
                  >
                    <Form.Label>Teléfono:</Form.Label>
                    <Form.Control
                      name="clientPhoneNumber"
                      type="tel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientPhoneNumber}
                      isInvalid={errors.clientPhoneNumber}
                      disabled={isSubmitting}
                      placeholder="Teléfono"
                    />
                    <div className="error-feedback">
                      {errors.clientPhoneNumber &&
                        touched.clientPhoneNumber &&
                        errors.clientPhoneNumber}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="12"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Dirección:</Form.Label>
                    <Form.Control
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      isInvalid={errors.address}
                      disabled={isSubmitting}
                      placeholder="Dirección"
                    />
                    <div className="error-feedback">
                      {errors.address && touched.address && errors.address}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Número:</Form.Label>
                    <Form.Control
                      name="streetNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.streetNumber}
                      isInvalid={errors.streetNumber}
                      disabled={isSubmitting}
                      placeholder="Número"
                    />
                    <div className="error-feedback">
                      {errors.streetNumber &&
                        touched.streetNumber &&
                        errors.streetNumber}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Colonia:</Form.Label>
                    <Form.Control
                      name="neighborhood"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.neighborhood}
                      isInvalid={errors.neighborhood}
                      disabled={isSubmitting}
                      placeholder="Colonia"
                    />
                    <div className="error-feedback">
                      {errors.neighborhood &&
                        touched.neighborhood &&
                        errors.neighborhood}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Referencia:</Form.Label>
                    <Form.Control
                      name="reference"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                      isInvalid={errors.reference}
                      disabled={isSubmitting}
                      placeholder="Referencia"
                    />
                    <div className="error-feedback">
                      {errors.reference &&
                        touched.reference &&
                        errors.reference}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Código Postal:</Form.Label>
                    <Form.Control
                      name="postalCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      isInvalid={errors.postalCode}
                      disabled={isSubmitting}
                      placeholder="Código Postal"
                    />
                    <div className="error-feedback">
                      {errors.postalCode &&
                        touched.postalCode &&
                        errors.postalCode}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Municipio:</Form.Label>
                    <Form.Control
                      name="municipality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.municipality}
                      isInvalid={errors.municipality}
                      disabled={isSubmitting}
                      placeholder="Municipio"
                    />
                    <div className="error-feedback">
                      {errors.municipality &&
                        touched.municipality &&
                        errors.municipality}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Ciudad:</Form.Label>
                    <Form.Control
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      isInvalid={errors.city}
                      disabled={isSubmitting}
                      placeholder="Ciudad"
                    />
                    <div className="error-feedback">
                      {errors.city && touched.city && errors.city}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Estado:</Form.Label>
                    <Form.Control
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      isInvalid={errors.state}
                      placeholder="Estado"
                      disabled={isSubmitting}
                    />
                    <div className="error-feedback">
                      {errors.state && touched.state && errors.state}
                    </div>
                  </Form.Group>
                </Row>
                <Row className="client-data__payment">
                  <h5>
                    <span>2</span> Detalles de pago{" "}
                    <img src={lockIconUrl} alt="La Relojeríair" />
                  </h5>
                  <div className="d-flex flex-column flex-lg-row align-items-lg-center client-data__payment__cards mt-2">
                    <p className="mb-2 mb-lg-0 me-2">
                      Aceptamos pagos de las siguientes tarjetas de
                      crédito/débito:
                    </p>
                    <div>
                      <img src={creditCard1Url} alt="La Relojeríair" />
                      <img
                        src={creditCard2Url}
                        className="mx-2"
                        alt="La Relojeríair"
                      />
                      <img src={creditCard3Url} alt="La Relojeríair" />
                    </div>
                  </div>
                  <CardSection
                  // stripeError={error}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      margin: ".6rem ",
                      padding: "0",
                    }}
                  >
                    {formError}
                  </span>
                </Row>
                <Row className="other-data__payment">
                  <h5>
                    <span>3</span> Otro método de pago
                  </h5>
                  <p>
                    Si quieres un descuento extra en tu compra, el pago debes
                    efectuarlo por medio de retiro sin tarjeta, comunícate con
                    nosotros por{" "}
                    <a
                      href="https://wa.me/524495457748"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className=" fab fa-whatsapp"></i> WhatsApp
                    </a>
                  </p>
                  <span> Sólo aplica en compras menores a $9,000.00</span>
                </Row>
                <Button
                  className={`confirm-payment ${
                    loading || loadingOrderCreation ? "loading" : ""
                  }`}
                  type="submit"
                  disabled={isSubmitting || loading || loadingOrderCreation}
                >
                  {loading ? (
                    <span>Procesando pago...</span>
                  ) : loadingOrderCreation ? (
                    <span>Procesando orden...</span>
                  ) : (
                    <span>Pagar {formatMoney(totalPriceCart)}</span>
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
      <Col xs="12" lg="4" className="order-summary">
        <OrderSummary
          cartItems={cartItems}
          productsCart={productsCart}
          total={totalPriceCart}
          totalProductsCart={totalProductsCart}
        />
      </Col>
    </Row>
  );
}
