import { BASE_PATH } from "../utils/constants";
import { authFetch } from "../utils/fetch";

/*
  // @route    GET /api/rifas?[populate]=*
  // @desc     Get all rifas products
  // @access   Public
*/
export async function getRafflesProducts() {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    // const url = `${BASE_PATH}/api/subastas`;
    const url = `${process.env.REACT_APP_API_URL}/api/rifas`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return { error: error.message };
  }
}

/*
  // @route    GET /api/rifas/ID?[populate]=*
  // @desc     Get a specific rifa product
  // @access   Public
*/
export async function getBidProduct(productId, token) {
  // console.log(token);
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${process.env.REACT_APP_API_URL}/api/rifas/${productId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return { error: error.message };
  }
}

/*
  // @route    PUT /api/rifas/ID
  // @desc     Purchase a ticket
  // @access   Private
*/
export async function postBid(productId, bid, logout) {
  const data = JSON.stringify(bid);
  // console.log(bid);
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/rifas/${productId}`;
    const params = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const result = await authFetch(url, params, logout);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  // @route    GET /api/portada-rifa
  // @desc     Get hero background for Rifas page
  // @access   Public
*/
export async function getRafflesHero() {
  try {
    // const url = `${BASE_PATH}/api/portada-rifa?[populate]=*`;
    const url = `${process.env.REACT_APP_API_URL}/api/portadas/3`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return { error: error.message };
  }
}
