import { useAuth } from "../context/AuthContext";
import { useCart } from "../context/CartContext";
import { removeToken } from "../api/token";

export function useLogout() {
  const { setAuth } = useAuth();
  const { resetCart } = useCart();

  const logout = () => {
    resetCart(); // Now it can reset the cart without modifying providers
    removeToken();
    setAuth({});
  };

  return { logout };
}
