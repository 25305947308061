import { BASE_PATH } from "../utils/constants";

export async function getProducts(pageSize) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos?depth=1&where[_status][equals]=published`;
    const response = await fetch(url);
    if (!response.ok) {
      return {
        error: true,
        message: `Error in products ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getPopularProducts() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/populares`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getProduct(id) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos/${id}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[marca][slug][$eq]=BRANDNAME
  // @desc     Get all products from a specific brand
  // @access   Public
*/
export async function getBrandProducts(brandName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    // const url = `${BASE_PATH}/api/productos?[populate]=*&filters[marca][slug][$eq]=${brandName}`;
    const url = `${process.env.REACT_APP_API_URL}/api/productos?where[marca.slug][equals]=${brandName}&depth=1`;

    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[genero][slug][$eq]=GENRENAME
  // @desc     Get all products from a specific genre
  // @access   Public
*/
export async function getGenreProducts(genreName) {
  try {
    // const url = `${BASE_PATH}/api/productos?[populate]=*&filters[genero][slug][$eq]=${genreName}`;
    const url = `${process.env.REACT_APP_API_URL}/api/productos?where[genero.nombre][equals]=${genreName}&depth=1`;

    const response = await fetch(url);
    if (!response.ok) {
      // Throw an error with the status code and message
      return {
        error: true,
        message: `Error in productos género ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/subastas/AUCTIONPRODUCID?[populate]=*
  // @desc     Get a specific auction product from an id
  // @access   Public
*/
export async function getAuctionProduct(id) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${process.env.REACT_APP_API_URL}/api/subastas/${id}?depth=1`;
    const response = await fetch(url);
    if (!response.ok) {
      // Throw an error with the status code and message
      return {
        error: true,
        message: `Error en traer información de producto subasta ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[condicion][slug][$eq]=CONDITIONNAME
  // @desc     Get all products from a specific condition
  // @access   Public
*/
export async function getConditionProducts(condition) {
  try {
    // const url = `${BASE_PATH}/api/productos?[populate]=*&filters[condicion][slug][$eq]=${condition}`;
    const url = `${process.env.REACT_APP_API_URL}/api/productos?where[condicion.nombre][equals]=${condition}&depth=1`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/portada-catalogo
  // @desc     Get hero background for Catalogue page
  // @access   Public
*/
export async function getHeroCatalogue() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/portadas/1`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}
