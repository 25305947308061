import { BASE_PATH } from "../utils/constants";

export async function getBrands() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/marcas`;
    const response = await fetch(url);
    if (!response.ok) {
      // Throw an error with the status code and message
      return {
        error: true,
        message: `Error in marcas ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET ?filters[slug][$eq]=SLUG&[populate]=*
  // @desc     Get a brand from a specific slug
  // @access   Public
*/
export async function getBrand(brandName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    // const url = `${BASE_PATH}/api/marcas?filters[slug][$eq]=${brandName}&[populate]=*`;
    const url = `${process.env.REACT_APP_API_URL}/api/marcas?where[slug][equals]=${brandName}&depth=1`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}
