import React from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Col from "react-bootstrap/Col";

// Custom CSS
import "./orderList.styles.scss";

// Utils
import formatMoney from "../../utils/formatMoney";

export default function OrderList({ orderItems }) {
  // console.log(orderItems);
  return orderItems?.map((item, key) => (
    <Col xs="12" lg="4" className="orders__container__list" key={key}>
      <Link to={`/profile/orders/${item.orderId}`} className="order-card">
        <p>
          <span>Id de orden: </span>
          <span>{item.orderId}</span>
        </p>
        <p>
          <span>Fecha: </span>
          <span>
            {new Date(item.createdAt).toLocaleDateString("es-MX", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </p>
        <p>
          <span>Cantidad de productos: </span>
          <span>{item.orderItems.length}</span>
        </p>
        <p>
          <span>Total: </span>
          <span>{formatMoney(item.total)} MXN</span>
        </p>
      </Link>
    </Col>
  ));
}
