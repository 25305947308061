import { authFetch } from "../utils/fetch";
import { BASE_PATH } from "../utils/constants";

export async function registerApi(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/customers`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function loginApi(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/customers/login`;
    const params = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getMeApi(token) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/customers/me`;
    const params = {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, params);
    // console.log(response);
    const result = await response.json();
    return result;
  } catch (error) {
    return null;
  }
}

export async function forgotPassword(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/customers/forgot-password`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function resetPassword(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/customers/reset-password`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
