import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BASE_PATH } from "../utils/constants";

// Form validation
import { Formik } from "formik";
import * as yup from "yup";

// Context
import AlertContext from "../context/AlertContext";

// Axios for sending email
import axios from "axios";

// Bootstrap components
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom CSS
import "../assets/scss/pages/signUp.scss";

// API
import { registerApi } from "../api/user";

// Utils
import useAuth from "../hooks/useAuth";

const initialValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  password: "",
};

let schema = yup.object().shape({
  fullName: yup.string().required("Este campo es requerido"),
  email: yup.string().email().required("Este campo es requerido"),
  phoneNumber: yup.number().required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
});

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { auth, logout } = useAuth();

  const history = useHistory();
  const alert = useContext(AlertContext);
  return (
    <div className="sign-up">
      <Container fluid>
        <Row>
          <Col xs="12" lg="6">
            <div className="sign-up__media"></div>
          </Col>
          <Col xs="12" lg="6" className="sign-up__content">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // setTimeout(() => {
                //   alert(JSON.stringify(values, null, 2));
                //   setSubmitting(false);
                // }, 400);
                const response = await registerApi(values);
                if (response.doc && !response.errors) {
                  history.push("/login");
                  alert.success(
                    "Registro exitoso, revisa la bandeja de entrada de tu correo para más información."
                  );
                  resetForm();
                  // console.log(response);
                  // try {
                  //   axios.post(
                  //     `${BASE_PATH}/api/email`,
                  //     {
                  //       To: `${values.email}`,
                  //       Subject: "Confirmación de registro",
                  //       HtmlBody: `
                  //       <html lang="es">
                  //       <head>
                  //         <meta charset="UTF-8">
                  //         <meta http-equiv="X-UA-Compatible" content="IE=edge">
                  //         <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  //         <link rel="preconnect" href="https://fonts.googleapis.com">
                  //         <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                  //         <link href="https://fonts.googleapis.com/css2?family=Epilogue:wght@500;600&family=Montserrat&display=swap" rel="stylesheet">
                  //       </head>
                  //       <body>
                  //         <div style="font-family: 'Montserrat', sans-serif; background-color: #F3F3F3; padding: 2rem 1rem; height: auto;">
                  //           <div
                  //             style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #0D314F; padding:0; overflow: hidden;">
                  //             <div class="heading" style="background-color: #FBFBFB; padding: 2rem; text-align: center;">
                  //               <img src="https://larelojeriair.com/static/media/logo.6c688721ca19b8134e9eb4c1cc34428c.svg" style="height: 60px;" alt="La Relojeríair"/>
                  //             </div>
                  //             <div style="padding: 1rem 1.5rem;">
                  //               <h2
                  //                 style="text-transform: uppercase; font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000; text-align: center;">
                  //                 Confirmación de registro</h2>
                  //               <div style="text-align: center;">
                  //                 <img src="https://larelojeriair.com/static/media/check-icon--green--sm.2689592d6d9a129756b67c8955b16f5c.svg" alt="La Relojeríair" title="La Relojeríair"
                  //                   style="display: block; margin: 1rem auto;" width="80px" height="auto" />
                  //                 <h3 style="margin-bottom: .5rem;">Te damos la bienvenida a la tienda en línea de La Relojeríair</h3>
                  //                 <p style="margin: 1rem auto; color: #000; width: 90%; line-height: 1.7;">Registrándote obtienes grandes
                  //                   beneficios, como
                  //                   comprar relojes exclusivos a través de nuestra plataforma, publicar ofertas en todas las subastas y mucho
                  //                   más.</p>
                  //               </div>
                  //             </div>
                  //           </div>
                  //           <div class="coupon"
                  //             style="background: #0D314F; width: 90vw; margin: 1rem auto; color: #fff; padding: 2.3rem 1rem; text-align: center; box-sizing: border-box; ">
                  //             <h3 style="font-size: 3rem; margin: 0; font-family: 'Epilogue', sans-serif; line-height: 1;">Síguenos en
                  //               facebook</h3>
                  //             <a style="display: block; text-decoration: none; font-weight: bolder; font-size: 1.5rem; margin-top: 1rem; color: #fff;"
                  //               href="" target="_blank">La Relojeríair</a>
                  //           </div>
                  //           </div>
                  //       </body>
                  //       </html>
                  //       `,
                  //     }
                  //     // {
                  //     //   headers: {
                  //     //     Authorization: `Bearer ${response?.jwt}`,
                  //     //   },
                  //     // }
                  //   );
                  //   // setSuccess(true);
                  //   alert.success(
                  //     "Registro exitoso, revisa la bandeja de entrada de tu correo para más información."
                  //   );
                  //   // setTimeout(() => {
                  //   //   setSuccess(false);
                  //   // }, 1500);
                  //   history.push("/login");
                  // } catch (error) {
                  //   console.log(error);
                  // }
                } else {
                  // console.log(response.errors);
                  if (response.errors[0].name === "ValidationError") {
                    alert.error("El cupo se ha alcanzado.");
                    // setError(
                    //   "El email que quieres registrar ya está registrado."
                    // );
                    // setTimeout(() => {
                    //   setError("");
                    // }, 3000);
                  } else {
                    alert.error(
                      "Error al registrar el usuario, inténtelo más tarde."
                    );
                    // setError(
                    //   "Error al registrar el usuario, inténtelo más tarde."
                    // );
                    // setTimeout(() => {
                    //   setError("");
                    // }, 3000);
                  }
                  history.push("/catalogue");
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <>
                  {success && (
                    <Alert variant="success">
                      Registro exitoso, revisa la bandeja de entrada de tu
                      correo para más información.
                    </Alert>
                  )}
                  <h2>Regístrate</h2>
                  <p>Regístrate llenando todos los campos a continuación:</p>
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        lg="12"
                        className="mb-3 form-group"
                        // controlId="formGridName"
                      >
                        <Form.Label>Nombre completo:</Form.Label>
                        <Form.Control
                          name="fullName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullName}
                          isInvalid={errors.fullName}
                          placeholder="Tu nombre"
                        />
                        <div className="error-feedback">
                          {errors.fullName &&
                            touched.fullName &&
                            errors.fullName}
                        </div>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg="12"
                        className="mb-3 form-group"
                        // controlId="formGridEmail"
                      >
                        <Form.Label>Correo electrónico:</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          isInvalid={errors.email}
                          placeholder="Email"
                        />
                        <div className="error-feedback">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg="12"
                        className=" form-group mb-3"
                        // controlId="formGridPhone"
                      >
                        <Form.Label>Teléfono:</Form.Label>
                        <Form.Control
                          name="phoneNumber"
                          type="tel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          isInvalid={errors.phoneNumber}
                          placeholder="Teléfono"
                        />
                        <div className="error-feedback">
                          {errors.phoneNumber &&
                            touched.phoneNumber &&
                            errors.phoneNumber}
                        </div>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        lg="12"
                        className="form-group"
                        controlId="formGridPassword"
                      >
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Contraseña"
                          onChange={handleChange}
                          isInvalid={errors.password}
                        />
                        <div className="error-feedback">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </div>
                      </Form.Group>
                    </Row>
                    {error && <p className="errors">{error}</p>}
                    <Button
                      variant="primary"
                      type="submit"
                      className="auth__cta"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Cargando..." : "Registrar"}
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <div className="register__container"></div>
    </div>
  );
}
