import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Axios
import axios from "axios";

// Bootstrap components
import Container from "react-bootstrap/Container";

const VerifyEmail = () => {
  const location = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState("Verifying email...");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (!token) {
      setMessage("Invalid verification link.");
      return;
    }

    // Send the token to Payload CMS
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/customers/verify/${token}`)
      .then(() => {
        setMessage("Email verificado exitosamente. Redireccionando...");
        setTimeout(() => history.push("/login"), 3000); // Redirect after success
      })
      .catch((error) => {
        console.error("Verification failed:", error);
        setMessage(
          `Falló la verificación de email. Intenta de nuevo después. `
        );
      });
  }, [location, history]);

  return (
    <Container className="py-3 h-50vh d-flex align-items-center justify-content-center">
      <p className="text-center">{message}</p>
    </Container>
  );
};

export default VerifyEmail;
