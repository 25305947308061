// import { createContext } from "react";

// const CartContext = createContext({
//   productsCart: 0,
//   total: 0,
//   addProductCart: () => null,
//   decreaseProductCart: () => null,
//   getProductsCart: () => null,
//   increaseProductCart: () => null,
//   removeAllProductsCart: () => null,
//   removeProductCart: () => null,
// });

// export default CartContext;

import { createContext, useContext, useState, useEffect, useRef } from "react";

// API
import { setToken, getToken, removeToken } from "../api/token";
import {
  addProductCart,
  countProductsCart,
  decreaseProductCart,
  deleteCartItemApi,
  getProductsCart,
  getTotalCartPrice,
  increaseProductCart,
  removeAllProductsCart,
  addToCart,
} from "../api/cart";

// Context
import { useAuth } from "./AuthContext";
import AlertContext from "./AlertContext";

export const CartContext = createContext();

export const useCart = () => {
  const context = useContext(CartContext);
  return context;
};

export function CartProvider({ children }) {
  const [totalProductsCart, setTotalProductsCart] = useState(0);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [reloadCart, setReloadCart] = useState(false);
  const { auth, logout } = useAuth();

  const alert = useContext(AlertContext);

  const hasFetched = useRef(false);

  // const addProduct = async (product, quantity, auth) => {
  //   // setLoading(true);
  //   setReloadCart(true);
  //   console.log(product);
  //   const token = getToken();
  //   if (auth.token) {
  //     try {
  //       const response = await addToCart(product, quantity, auth);
  //       console.log(response);
  //       if (response.docs) {
  //         if (
  //           cartItems.find((item) => item.id === response.docs[0].id) !==
  //           undefined
  //         ) {
  //           console.log("ya estaba ");
  //           let updatedCartItems = cartItems.map((item) => {
  //             if (item.id === response.docs[0].id) {
  //               return {
  //                 ...item,
  //                 attributes: {
  //                   ...item.attributes,
  //                   cantidad: item.cantidad + quantity,
  //                 },
  //               };
  //             }
  //             return item;
  //           });
  //           setCartItems(updatedCartItems);
  //         } else {
  //           console.log("primero");
  //           setCartItems([
  //             ...cartItems,
  //             {
  //               id: response.docs[0].id,
  //               attributes: {
  //                 cantidad: quantity,
  //                 productos: { data: [product] },
  //               },
  //             },
  //           ]);
  //         }

  //         return response;
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     setReloadCart(false);
  //     // if (response.data) {
  //     // } else {
  //     //   setReloadCart(false);
  //     // }
  //     // setLoading(false);
  //   } else {
  //     console.error("Para comprar un producto tienes que iniciar sesión");
  //     setReloadCart(false);
  //   }
  // };

  const addProduct = async (product, quantity, auth) => {
    setReloadCart(true);
    // console.log(product);
    const token = getToken();
    if (auth.token) {
      try {
        const response = await addToCart(product, quantity, auth);
        // console.log(response);

        if (response && response.data) {
          // If the product is already in the cart, update the quantity
          if (
            cartItems.find((item) => item.id === response.data.doc.id) !==
            undefined
          ) {
            // console.log("Ya estaba en el carrito");

            let updatedCartItems = cartItems.map((item) => {
              if (item.id === response.data.doc.id) {
                return {
                  ...item,

                  ...item.attributes,
                  quantity: item.quantity + quantity,
                };
              }
              return item;
            });
            setCartItems(updatedCartItems);
            // localStorage.setItem("cart", JSON.stringify(updatedCartItems));

            return {
              success: true,
              action: "updated",
              docs: response.data.doc,
            };
          } else {
            // If the product is not in the cart, add it
            // console.log("Producto agregado al carrito");
            const { user, ...filteredDoc } = response.data.doc;
            setCartItems([
              ...cartItems,
              {
                id: response.data.doc.id,
                ...filteredDoc,
                // quantity: quantity,
                // productos: { data: [product] },
              },
            ]);
            // localStorage.setItem(
            //   "cart",
            //   JSON.stringify([
            //     ...cartItems,
            //     {
            //       id: response.data.doc.id,
            //       attributes: {
            //         cantidad: quantity,
            //         productos: { data: [product] },
            //       },
            //     },
            //   ])
            // );
            return { success: true, action: "added", docs: response.data.doc };
          }
        } else {
          return { success: false, error: "No docs found in response." };
        }
      } catch (error) {
        console.error("Error adding product:", error);
        return { success: false, error: error.message };
      }
    } else {
      console.error("Para comprar un producto, debes iniciar sesión.");
      setReloadCart(false);
      return { success: false, error: "No token found." };
    }
  };

  const increaseProduct = async (
    userId,
    quantity,
    product,
    productId,
    auth
  ) => {
    // console.log("hola");
    setReloadCart(true);
    try {
      const response = await increaseProductCart(
        userId,
        quantity,
        product,
        productId,
        auth
      );
      // console.log(response);
      const foundIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === response.data.doc.id
      );
      if (foundIndex !== -1) {
        cartItems[foundIndex].quantity = quantity;
        setCartItems(cartItems);
        setTotalProductsCart(
          cartItems.reduce((total, product) => total + product.quantity, 0)
        );
        setTotalPriceCart(
          cartItems.reduce(function (tally, cartItem) {
            return tally + cartItem?.quantity * cartItem?.product.precio;
          }, 0)
        );
      }
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
  };
  const decreaseProduct = async (
    userId,
    quantity,
    product,
    productId,
    auth
  ) => {
    // console.log("hola");
    setReloadCart(true);

    try {
      const response = await decreaseProductCart(
        userId,
        quantity,
        product,
        productId,
        auth
      );
      // console.log(response, "Response");

      if (response.error) {
        alert.error(
          `Hubo un error al actualizar tu carrito. ${response.error}`
        );
        throw new Error(
          response.message || "Failed to decrease product quantity"
        );
      }

      const foundIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === response.data.doc.id
      );

      // if (foundIndex !== -1) {
      // const updatedCartItems = cartItems.map((item, index) =>
      //   index === foundIndex
      //     ? { ...item, quantity: Math.max(quantity - 1, 0) }
      //     : item
      // );
      // setCartItems(updatedCartItems); // Ensure reactivity
      // cartItems[foundIndex].quantity = quantity;
      // setTotalProductsCart(
      //   cartItems.reduce(
      //     (total, product) => Math.max(total - product.quantity),
      //     0
      //   )
      // );
      // setTotalPriceCart(
      //   cartItems.reduce(function (tally, cartItem) {
      //     return tally + cartItem?.quantity * cartItem?.product.precio;
      //   }, 0)
      // );
      // alert.success("Carrito actualizado con éxito.", 0.5);
      // }
      cartItems[foundIndex].quantity = quantity;
      setCartItems(cartItems);
      setTotalProductsCart(
        cartItems.reduce((total, product) => total + product.quantity, 0)
      );
      setTotalPriceCart(
        cartItems.reduce(function (tally, cartItem) {
          return tally + cartItem?.quantity * cartItem?.product?.precio;
        }, 0)
      );
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log("Failed to decrease product quantity", error);
      alert.error(`Hubo un error al actualizar tu carrito. ${error.message}`);
      setReloadCart(false);
      return { success: false, error: error.message };
    }
  };

  const removeProduct = async (userId, productId, auth) => {
    // console.log("hola");
    setReloadCart(true);

    try {
      const response = await deleteCartItemApi(userId, productId, auth);
      // console.log(response);
      if (response.data) {
        setCartItems((prev) =>
          prev.filter((item) => {
            return item.id !== productId;
          })
        );
        alert.success("Reloj eliminado de tu carrito.", 1);
        setTotalProductsCart(
          cartItems.reduce((total, product) => total + product.quantity, 0)
        );
        setTotalPriceCart(
          cartItems.reduce(function (tally, cartItem) {
            return tally + cartItem?.quantity * cartItem?.product.precio;
          }, 0)
        );
      }
      // console.log(response);
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
    setReloadCart(false);
    // if (response.data) {
    // } else {
    //   setReloadCart(false);
    //   console.log(response);
    // }
    // try {
    // } catch (error) {
    //   console.log(error);
    // }
    // const token = getToken();
    // if (token) {
    // } else {
    //   console.error("Para comprar un producto tienes que iniciar sesión");
    // }
  };

  const resetCart = (cartId, auth) => {
    setTotalProductsCart(0);
    setTotalPriceCart(0);
    // removeAllProductsCart(cartId, auth);
    // setCartItems([]);
    // setReloadCart(true);
  };

  useEffect(() => {
    // console.log(auth.idUser);
    if (!auth.idUser || hasFetched.current) {
      // console.log("yo there was one before");
      return;
    }
    const getUserCart = async () => {
      // console.log("yo there wasnt one before");
      setReloadCart(true);
      const response = await getProductsCart(auth?.idUser, auth.token);
      if (response?.docs.length > 0) {
        for (let index = 0; index < response.docs.length; index++) {
          delete response.docs[index].user;
        }
        setCartItems(response.docs);
        setReloadCart(false);
        // console.log(response);
      } else {
        setCartItems([]);
        setReloadCart(false);
      }
    };
    getUserCart();
  }, [auth.idUser]);

  // useEffect(() => {
  //   if (auth.idUser) {
  //     // (async () => {
  //     //   setTotalProductsCart(await countProductsCart(auth?.idUser, logout));
  //     // })();
  //     setTotalProductsCart(
  //       cartItems.reduce(
  //         (total, product) => total + product.attributes.cantidad,
  //         0
  //       )
  //     );
  //   }
  // }, []);

  useEffect(() => {
    // if (auth.idUser) {
    //   // (async () => {
    //   //   setTotalPriceCart(await getTotalCartPrice(auth?.idUser, logout));
    //   // })();
    //   setTotalPriceCart(
    //     cartItems.reduce(function (tally, cartItem) {
    //       return (
    //         tally +
    //         cartItem?.attributes.cantidad *
    //           cartItem?.attributes.productos.data[0]?.attributes.precio
    //       );
    //     }, 0)
    //   );
    // }
    setTotalProductsCart(
      cartItems.reduce((total, product) => total + product.quantity, 0)
    );
    setTotalPriceCart(
      cartItems.reduce(function (tally, cartItem) {
        return tally + cartItem?.quantity * cartItem?.product?.precio;
      }, 0)
    );
    if (cartItems.length > 0) {
    } else {
      setTotalPriceCart(0);
      setTotalProductsCart(0);
    }
  }, [cartItems, reloadCart]);

  return (
    <CartContext.Provider
      value={{
        totalProductsCart,
        totalPriceCart,
        cartItems,
        setCartItems,
        addProduct,
        increaseProduct,
        decreaseProduct,
        removeProduct,
        resetCart,
        setTotalPriceCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
