import { authFetch } from "../utils/fetch";
import { BASE_PATH } from "../utils/constants";

export async function getComments() {
  try {
    const sortItem = "sort=createdAt:asc";
    // const url = `${process.env.REACT_APP_API_URL}/api/comentarios?${sortItem}&populate=usuario`;
    const url = `${process.env.REACT_APP_API_URL}/api/comentarios?depth=1`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function postComment(comment, logout) {
  const data = JSON.stringify(comment);
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/comentarios`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const result = await authFetch(url, params, logout);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
