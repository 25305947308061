// import { createContext } from "react";

// const AuthContext = createContext({
//   auth: undefined,
//   login: () => null,
//   logout: () => null,
//   setReloadUser: () => null,
// });

// export default AuthContext;

import { createContext, useContext, useState, useEffect } from "react";

import { useCart } from "./CartContext";
// import { useLogout } from "../hooks/useLogout";

// API
import { setToken, getToken, removeToken } from "../api/token";
import { getMeApi } from "../api/user";
import {
  addProductCart,
  countProductsCart,
  decreaseProductCart,
  deleteCartItemApi,
  getProductsCart,
  getTotalCartPrice,
  increaseProductCart,
  removeAllProductsCart,
} from "../api/cart";

// Utils
import jwtDecode from "jwt-decode";

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({});
  const [user, setUser] = useState({});
  // const { resetCart } = useCart();
  const [totalProductsCart, setTotalProductsCart] = useState(0);
  // const { logout } = useLogout();

  // const logout = () => {
  //   if (auth) {
  //     removeToken();
  //     setAuth({});
  //     // resetCart();
  //     return;
  //   }
  // };

  const login = (token) => {
    setToken(token);
    setAuth({
      token,
      idUser: jwtDecode(token).id,
    });
  };

  const token = getToken();
  useEffect(() => {
    // console.log(token);
    const fetchData = async () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Convert ms to seconds
        // console.log(decodedToken, "decodedTOken");

        if (decodedToken.exp > currentTime) {
          setAuth({
            token,
            idUser: jwtDecode(token).id,
            datita: jwtDecode(token),
          });
          const response = await getMeApi(token);
          // console.log(auth);
          const { collection, strategy, user, ...rest } = response;
          const {
            loginAttempts,
            updatedAt,
            _verified,
            blocked,
            ...filteredUser
          } = user;
          setUser({ ...rest, user: filteredUser });
          // setUser(response);
          // console.log(response);
        }
      } else {
        // console.log("c cerrara sesion");
        // logout();
        removeToken();
        setAuth({});
        setUser({});
      }
    };

    fetchData();
  }, [token]);

  return (
    <AuthContext.Provider value={{ auth, login, user, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}
