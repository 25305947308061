import { BASE_PATH } from "../utils/constants";
import { authFetch } from "../utils/fetch";

// ----- GET FUNCTION

// export async function functionName() {
//   try {
//     const sortItem = "sort=createdAt:asc";
//     const url = `${BASE_PATH}/api/`;
//     const response = await fetch(url);
//     const result = await response.json();
//     return result;
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// }

// ------- POST FUNCTION
// export async function postOBJECT(object, logout) {
//   const data = JSON.stringify(object);
//   try {
//     const url = `${BASE_PATH}/api/`;
//     const params = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: data,
//     };
//     const result = await authFetch(url, params, logout);
//     return result;
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// }

export async function getRaffleProducts() {
  try {
    const sortItem = "sort=createdAt:asc";
    const url = `${process.env.REACT_APP_API_URL}/api/subastas?where[_status][equals]=published`;
    const response = await fetch(url);
    // Check if the response is ok (status 200-299)
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return {
      error:
        `Hubo un error al traer información de subastas: ${error.message}` ||
        "Hubo un error al traer información de subastas",
    };
  }
}

export async function postBid(raffleid, bid, logout) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/subastas/${raffleid}`;
    const params = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bid),
    };

    const result = await authFetch(url, params, logout);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  // @route    GET /api/portada-subasta
  // @desc     Get hero background for Subastas page
  // @access   Public
*/
export async function getHeroRaffles() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/portadas/2`;
    const response = await fetch(url);
    // console.log(response, "899999");
    if (!response.ok) {
      return {
        error: true,
        message: `Error en getHeroRaffles ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}
