import React, { useState, useEffect, useContext } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Custom CSS
import "../assets/scss/pages/order.scss";

// API
import { getMeApi } from "../api/user";
import { getUserOrders } from "../api/order";

// Context
import AlertContext from "../context/AlertContext";
import { useAuth } from "../context/AuthContext";

// Custom Components
import OrderList from "../components/OrderList/OrderList";

export default function Profile() {
  // const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [orderItems, setOrderItems] = useState([]);
  const { auth, logout, user } = useAuth();

  const alert = useContext(AlertContext);
  // useEffect(() => {
  //   (async () => {
  //     const response = await getMeApi(logout);
  //     setUser(response);
  //   })();
  // }, [auth]);

  useEffect(() => {
    (async () => {
      if (user && Object.keys(user || {})?.length > 0) {
        const response = await getUserOrders(user?.user?.id, auth.token);
        if (response?.error) {
          console.error("Error fetching client's orders.", response.message);
          alert.error(
            "Hubo un error al traer información de tus ordenes. Por favor, intenta más tarde."
          );
        } else {
          setOrderItems(response.docs);
        }
        setLoading(false);
      }
    })();
  }, [user]);

  return (
    <div className="orders__wrapper">
      <Container className="orders__container">
        {loading ? (
          <p className="py-2">Cargando ordenes...</p>
        ) : (
          <>
            <div className="orders__container__heading">
              <h2>
                Bienvenida/o <span>{user?.user.fullName}.</span>
              </h2>
              <h3 className="mb-2">Historial de órdenes.</h3>
              <p className="mb-2">Número de ordenes: {orderItems.length}</p>
              <span className="text-muted">
                Haz clic sobre una orden para saber más detalles.
              </span>
            </div>
            <Row>
              <OrderList orderItems={orderItems} />
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
