import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Context
// import { useProducts } from "../../context/ProductsContext";

// API
import { getBrands } from "../../api/brand";

//Assets
import Logo from "../../assets/images/logo.svg";
import TiktokIcon from "../../assets/images/tiktok-icon--red.svg";

const Footer = () => {
  // const { brands } = useProducts();
  // const [brands, setBrands] = useState([]);
  // useEffect(() => {
  //   (async () => {
  //     const response = await getBrands();
  //     if (response !== null) {
  //       for (let index = 0; index < response.data.length; index++) {
  //         delete response.data[index].attributes.createdAt;
  //         delete response.data[index].attributes.publishedAt;
  //         delete response.data[index].attributes.updatedAt;
  //       }
  //       setBrands(response);
  //     }
  //     // if (query.platform) {
  //     // }
  //   })();
  // }, []);
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__main">
          <div className="row no-gutters">
            <div className="footer__main__section col-12 col-md-3">
              <Link to="/" className="footer__logo-container">
                <img
                  src={Logo}
                  alt="El Mundo del Reloj"
                  className="footer__logo"
                />
              </Link>
              <p className="footer__text">
                Somos una empresa dedicada a la venta de relojes originales por
                menudeo y mayoreo en México con la capacidad técnica e idónea
                para el manejo, identificación y reparación de piezas sencillas
                y de lujo.
              </p>
            </div>
            <div className="footer__main__section footer__main__section--links col-12 col-md-7">
              <div className="footer__main__link-container footer__main__link-container--brands col-6 col-md-4">
                <p className="footer__main__link__header footer__main__link__header--desktop">
                  Relojes
                </p>
                <p className="footer__main__link__header footer__main__link__header--mobile">
                  Marcas
                </p>
                {/* {brands.data?.map((brand, key) => (
                  <Link
                    to={`/brand/${brand.attributes.slug}`}
                    className="footer__main__link"
                    key={key}
                  >
                    <p className="footer__main__link__text">
                      {brand.attributes.nombre}
                    </p>
                  </Link>
                ))} */}

                {/* <Link to="/catalogue" className="footer__main__link">
                  <p className="footer__main__link__text">Rado</p>
                </Link>
                <Link to="/catalogue" className="footer__main__link">
                  <p className="footer__main__link__text">Bulova</p>
                </Link>
                <Link to="/catalogue" className="footer__main__link">
                  <p className="footer__main__link__text">Rolex</p>
                </Link>
                <Link to="/catalogue" className="footer__main__link">
                  <p className="footer__main__link__text">Omega</p>
                </Link>
                <Link to="/catalogue" className="footer__main__link">
                  <p className="footer__main__link__text">Citizen</p>
                </Link>
                <Link to="/catalogue" className="footer__main__link">
                  <p className="footer__main__link__text">Hublot</p>
                </Link> */}
              </div>
              <div className="footer__main__link-container footer__main__link-container--desktop col-6 col-md-4">
                <Link to="/bids" className="footer__main__link ">
                  <p className="footer__main__link__header footer__main__link__header--bottom">
                    Subastas
                  </p>
                </Link>
              </div>
              <div className="footer__main__link-container col-6 col-md-4">
                <p className="footer__main__link__header">Información</p>
                <Link to="/#about-us" className="footer__main__link">
                  <p className="footer__main__link__text">Nosotros</p>
                </Link>
                <Link to="/politicas" className="footer__main__link">
                  <p className="footer__main__link__text">Políticas</p>
                </Link>
                <Link to="/envios" className="footer__main__link">
                  <p className="footer__main__link__text">
                    Envío y recepción de mercancía
                  </p>
                </Link>
                {/* <p className="footer__main__link__header footer__main__link__header--mobile">
                  Relojes
                </p>
                <Link
                  to="/catalogue"
                  className="footer__main__link footer__main__link--mobile"
                >
                  <p className="footer__main__link__text">Para hombre</p>
                </Link>
                <Link
                  to="/catalogue"
                  className="footer__main__link footer__main__link--mobile"
                >
                  <p className="footer__main__link__text">Para mujer</p>
                </Link>
                <Link
                  to="/catalogue"
                  className="footer__main__link footer__main__link--mobile"
                >
                  <p className="footer__main__link__text">Últimos modelos</p>
                </Link> */}
                {/* <p className="footer__main__link__header footer__main__link__header--bottom">
                  Nosotros
                </p> */}
              </div>
              {/* <div className="footer__main__link-container col-6 col-md-4">
                <p className="footer__main__link__header">Información</p>
                <Link to="/envios" className="footer__main__link">
                  <p className="footer__main__link__text">Nosotros</p>
                </Link>
                <Link to="/politicas" className="footer__main__link">
                  <p className="footer__main__link__text">Políticas</p>
                </Link>
                <Link to="/envios" className="footer__main__link">
                  <p className="footer__main__link__text">
                    Envío y recepción de mercancía
                  </p>
                </Link>
              </div> */}
              <div className="footer__main__link-container footer__main__link-container--mobile col-6">
                <p className="footer__main__socialLink__header">Social</p>
                <div className="footer__main__socialLink-container">
                  {/* <a
                    href="https://web.whatsapp.com/"
                    className="footer__main__socialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="footer__main__socialLink__icon fab fa-whatsapp"></i>
                  </a> */}
                  <a
                    href="https://www.instagram.com/larelojeriair/"
                    className="footer__main__socialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="footer__main__socialLink__icon fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/La-Relojeria-Original-103481138576106"
                    className="footer__main__socialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="footer__main__socialLink__icon fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@larelojeriaoriginal"
                    className="footer__main__socialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TiktokIcon} alt="La Relojeriair" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer__main__section footer__main__section--social col-6 col-md-2">
              <p className="footer__main__socialLink__header">Redes Sociales</p>
              <div className="footer__main__socialLink-container">
                {/* <a
                  href="https://web.whatsapp.com/"
                  className="footer__main__socialLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="footer__main__socialLink__icon fab fa-whatsapp"></i>
                </a> */}
                <a
                  href="https://www.instagram.com/larelojeriair/"
                  className="footer__main__socialLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="footer__main__socialLink__icon fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/La-Relojeria-Original-103481138576106"
                  className="footer__main__socialLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="footer__main__socialLink__icon fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.tiktok.com/@larelojeriaoriginal"
                  className="footer__main__socialLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TiktokIcon} alt="La Relojeriair" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__copyright col-12 col-md-6">
            <p className="footer__copyright__text">
              La Relojería. 2025. Todos los derechos reservados
            </p>
          </div>
          <div className="footer__credits col-12 col-md-6">
            <p className="footer__credits__text">
              Diseñado y desarrollado por{" "}
              <span className="quintech">QuinTech</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
