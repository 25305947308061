import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Form validation
// import { Formik } from "formik";
// import * as yup from "yup";

// Context
import AlertContext from "../../context/AlertContext";
import { useAuth } from "../../context/AuthContext";

// Custom components
import Timer from "react-compound-timer";
import moment from "moment-timezone";
// import { ProductCarousel } from "../Carousel/Carousel";
import { Carousel } from "react-responsive-carousel";

// Custom CSS
import "./raffleProduct.styles.scss";

// API
import { getMeApi } from "../../api/user";
import { postBid } from "../../api/raffle";
// import useAuth from "../../hooks/useAuth";

// Utils
import { BASE_PATH } from "../../utils/constants";
import formatMoney from "../../utils/formatMoney";
import useForm from "../../utils/useForm";
import useCart from "../../hooks/useCart";

// Media
import nationalLottery from "../../assets/images/national-lottery.png";
import Bids from "../../pages/Bids";

export default function RaffleProduct({ product }) {
  const [bids, setBids] = useState(product.bids);
  // const [user, setUser] = useState(undefined);
  // console.log(product);
  const [hasEnded, setHasEnded] = useState(false);
  const { auth, logout, user } = useAuth();
  const timer = useRef();
  const { addProductCart } = useCart();

  const alert = useContext(AlertContext);

  // useEffect(() => {
  //   (async () => {
  //     const response = await getMeApi(logout);
  //     if (response) {
  //       setUser(response);
  //     }
  //   })();
  // }, []);

  const counterValue = () => {
    const dateFromApi = product?.fechaFinal;
    const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
    const added = createdAt.clone();
    const now = moment.tz("America/Mexico_City");
    const duration = added.diff(now, "milliseconds");
    // console.log(duration);
    return duration;
  };
  // console.log(product);

  const [count, setCount] = useState(product?.pujaInicial);
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + product?.pujaSubsecuente);
  };
  const handleDecrement = () => {
    // Here to validate not to be less than last posted

    // Get all bids and loop through them to find last bid
    // if (bids.length === 0) {

    // }
    if (count > bids[bids.length - 1]?.bid) {
      setCount((prevCount) => prevCount - product?.pujaSubsecuente);
    } else if (count > product?.pujaInicial) {
      setCount((prevCount) => prevCount - product?.pujaSubsecuente);
    } else {
      return;
    }
  };

  const { inputs, handleChange, resetForm } = useForm({
    user: user?.username,
    bid: 0,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const formDataTemp = {
      // id: product.id,
      // data: {
      raffleEntries: [
        ...bids,
        { id: user.user.id, usuario: user.user.fullName, bid: count },
      ],
      // },
    };
    const response = await postBid(product.id, formDataTemp, logout);
    if (!response) {
      console.log("Hubo un error en tu solicitud de puja");
      alert.error("Hubo un error en tu solicitud de puja.");
    } else {
      alert.success("Tu puja fue publicada exitosamente.");
      // console.log(response);
      setBids(response.doc.raffleEntries);
      resetForm({
        user: user.username,
      });
      // setCount(
      //   response.purchasedTickets[response.purchasedTickets.length - 1].bid +
      //     product?.pujaSubsecuente
      // );
    }
  }

  useEffect(() => {
    if (bids.length === 0) {
      setCount(product?.pujaInicial);
    } else {
      setCount(bids[bids.length - 1].bid + product?.pujaSubsecuente);
    }
  }, [bids]);

  return (
    <Col xs="6" lg="3" className="raffle-product-card">
      <div className="raffle-product-card__media">
        <Carousel showStatus={false} showIndicators={false}>
          {product?.imagenes.map((image, key) => (
            <Link
              to={`/auction-product/${product.id}`}
              key={key}
              style={{ display: "block", height: "100%", width: "100%" }}
            >
              <img
                // src={image.attributes.url}
                src={`${process.env.REACT_APP_API_URL}${image.image.url}`}
                alt=""
              />
            </Link>
          ))}
        </Carousel>
      </div>
      <div className="raffle-product-card__body">
        <Link to={`/auction-product/${product.id}`}>
          <h5>{product?.nombreProducto}</h5>
        </Link>
        <span>La subasta termina en:</span>
        <p className="raffle-product-card__timer">
          <Timer
            initialTime={counterValue()}
            direction="backward"
            ref={timer}
            checkpoints={[
              {
                time: 0,
              },
            ]}
          >
            {() => (
              <>
                <Timer.Days /> días <Timer.Hours /> horas <Timer.Minutes />{" "}
                minutos <Timer.Seconds /> segundos{" "}
              </>
            )}
          </Timer>
        </p>
        <span>Precio del reloj en el mercado:</span>
        <h6>
          {formatMoney(product?.precio)} MXN <span></span>
        </h6>
        {/* <p className="winner-text">
          El ganador favor de comunicarse por{" "}
          <a href="https://wa.me/524495457748" target="_blank" rel="noreferrer">
            <i className=" fab fa-whatsapp"></i> WhatsApp
          </a>
        </p> */}
        {product?.ganador !== null &&
          product?.ganador !== "" &&
          product?.ganador !== undefined &&
          bids?.length > 0 && (
            <div className="product__winner">
              <p style={{ marginTop: "0" }}>
                🎉 Ganador: 🎉{" "}
                <span style={{ display: "inline-block" }}>
                  {/* {bids[bids.length - 1]?.usuario} */}
                </span>
                <a
                  href="https://wa.me/524495457748"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className=" fab fa-whatsapp"></i> Comunícate con nosotros
                  para reclamar tu premio
                </a>
              </p>
            </div>
          )}
        <span className="raffle-product-card__bids__next-bid">
          La siguiente puja es de:{" "}
          {bids.length > 0
            ? formatMoney(
                bids[bids?.length - 1]?.bid + product?.pujaSubsecuente
              )
            : formatMoney(product.pujaInicial)}
        </span>
        <h4>Pujas:</h4>
        <div className="raffle-product-card__bids__container">
          {Array.from(bids)
            ?.sort((a, b) => b.bid - a.bid)
            .map((bid, key) => (
              <div key={key} className="raffle-product-card__bids">
                <div className="d-flex justify-content-between w-100">
                  <p>
                    <span>Nombre: </span>
                    {bid.usuario}
                  </p>
                  <p className="mt-2 mt-lg-0">
                    <span>Puja: </span>
                    {formatMoney(bid.bid)} MXN
                  </p>
                </div>
              </div>
            ))}
        </div>
        <div className="raffle-product-card__bids__input">
          {!auth?.idUser && (
            <p>
              Debes <Link to="/login">iniciar sesión</Link> o{" "}
              <Link to="/register">registrarte</Link> para publicar una puja.
            </p>
          )}
          {auth.idUser && timer.current?.getTime() > 0 ? (
            <Form onSubmit={handleSubmit} id="bid-form">
              <Row>
                <Form.Group className="mb-1" controlId="formName">
                  <Form.Label>Nombre de usuario:</Form.Label>

                  <Form.Control
                    name="user"
                    plaintext
                    defaultValue={user?.user?.fullName}
                    readOnly
                  />
                  <Form.Text className="text-muted">
                    Este es tu nombre de usuario
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-2" controlId="formBid">
                  <Form.Label>Oferta:</Form.Label>
                  <div className="bid-container">
                    <button
                      type="button"
                      className="counter-action"
                      disabled={
                        count <=
                        bids[bids.length - 1]?.bid + product?.pujaSubsecuente
                      }
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                    <h5 className="form-control">{formatMoney(count)}</h5>
                    <button
                      type="button"
                      className="counter-action"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                </Form.Group>
              </Row>
              <Button className="send" type="submit">
                Enviar puja
              </Button>
            </Form>
          ) : (
            <></>
          )}
          {timer.current?.getTime() < 0 && <p>La subasta ha finalizado</p>}
        </div>
      </div>
    </Col>
  );
}
