import React from "react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";

import "./shareButton.styles.scss";

const title = "¡¡Descubre este reloj en La Relojeriair!!";

export function FacebookShare({ url }) {
  return (
    <a
      href={`fb-messenger://share?link=${encodeURIComponent(url)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="button-share d-block me-2"
    >
      <FacebookIcon size={45} round />
    </a>
  );
}
export function WhatsAppShare({ url }) {
  return (
    <WhatsappShareButton url={url} title={title} className="button-share">
      <WhatsappIcon size={45} round />
    </WhatsappShareButton>
  );
}
