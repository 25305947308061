import { createContext, useContext, useState, useEffect } from "react";

// API
import { getProducts } from "../api/product";
import { getBrands } from "../api/brand";

export const ProductsContext = createContext();

export const useProducts = () => {
  const context = useContext(ProductsContext);
  return context;
};

export function ProductsProvider({ children }) {
  const [productsLoading, setProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [error, setError] = useState(null);

  // SE NECESITAN QUITAR ASAP ESTOS USEEFFECT 😱
  // useEffect(() => {
  //   const getAPIProducts = async () => {
  //     setProductsLoading(true);
  //     try {
  //       const response = await getProducts();
  //       if (response !== null) {
  //         // for (let index = 0; index < response.data.length; index++) {
  //         //   delete response.data[index].attributes.createdAt;
  //         //   delete response.data[index].attributes.publishedAt;
  //         //   delete response.data[index].attributes.updatedAt;
  //         //   delete response.data[index].attributes.caratula;
  //         //   delete response.data[index].attributes.carcasa;
  //         //   delete response.data[index].attributes.condicion;
  //         //   delete response.data[index].attributes.correa;
  //         //   delete response.data[index].attributes.descripcion;
  //         //   delete response.data[index].attributes.eta;
  //         //   delete response.data[index].attributes.informacionGeneral;
  //         //   delete response.data[index].attributes.genero.data.attributes
  //         //     .createdAt;
  //         //   delete response.data[index].attributes.genero.data.attributes
  //         //     .publishedAt;
  //         //   delete response.data[index].attributes.genero.data.attributes
  //         //     .updatedAt;
  //         //   delete response.data[index].attributes.marca.data.attributes
  //         //     .createdAt;
  //         //   delete response.data[index].attributes.marca.data.attributes
  //         //     .publishedAt;
  //         //   delete response.data[index].attributes.marca.data.attributes
  //         //     .updatedAt;
  //         //   delete response.data[index].attributes.tipoCorrea.data.attributes
  //         //     .createdAt;
  //         //   delete response.data[index].attributes.tipoCorrea.data.attributes
  //         //     .publishedAt;
  //         //   delete response.data[index].attributes.tipoCorrea.data.attributes
  //         //     .updatedAt;
  //         // }
  //         setProducts(response.docs);
  //         setProductsLoading(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setProductsLoading(false);
  //     }
  //   };
  //   getAPIProducts();
  // }, []);

  // useEffect(() => {
  //   const getAPIBrands = async () => {
  //     try {
  //       const response = await getBrands();
  //       if (response !== null) {
  //         setBrands(response.docs);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getAPIBrands();
  // }, []);

  const fetchProducts = async () => {
    // if (products.length > 0) return; // Avoid unnecessary API calls
    setProductsLoading(true);
    setError(null);

    try {
      const response = await getProducts();
      if (response?.docs) {
        setProducts(response.docs);
      } else {
        setError("No products found.");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to fetch products.");
    } finally {
      setProductsLoading(false);
    }
  };

  const fetchBrands = async () => {
    if (brands.length > 0) return;
    try {
      const response = await getBrands();
      if (response?.docs) {
        setBrands(response.docs);
      } else {
        setError("No brands found.");
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
      setError("Failed to fetch brands.");
    }
  };
  return (
    <ProductsContext.Provider
      value={{
        products,
        productsLoading,
        brands,
        fetchBrands,
        fetchProducts,
        error,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}
