import { BASE_PATH } from "../utils/constants";

export async function getWatchGenres() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/generos`;
    const response = await fetch(url);
    if (!response.ok) {
      // Throw an error with the status code and message
      return {
        error: true,
        message: `Error in calibres ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/generos?[populate]=*&filters[slug][$eq]=${genreName}
  // @desc     Get information from a specific genre
  // @access   Public
*/
export async function getGenre(genreName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";

    // const url = `${BASE_PATH}/api/generos?[populate]=*&filters[slug][$eq]=${genreName}`;
    const url = `${process.env.REACT_APP_API_URL}/api/generos?where[nombre][equals]=${genreName}&depth=1`;

    const response = await fetch(url);
    if (!response.ok) {
      // Throw an error with the status code and message
      return {
        error: true,
        message: `Error in specific genre ${response.status}: ${response.statusText}`,
      };
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/condiciones?[populate]=*&filters[slug][$eq]=${condition}
  // @desc     Get information from a specific genre
  // @access   Public
*/
export async function getCondition(condition) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    // const url = `${BASE_PATH}/api/condiciones?[populate]=*&filters[slug][$eq]=${condition}`;
    const url = `${process.env.REACT_APP_API_URL}/api/condiciones?where[nombre][equals]=${condition}&depth=1`;

    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}
