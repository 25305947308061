import React, { useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";

// Context
import AlertContext from "../context/AlertContext";

// Custom styles
import "../assets/scss/pages/resetPassword.scss";

// Form validation
import { Formik } from "formik";
import * as Yup from "yup";

// API
import { resetPassword } from "../api/user";

const initialValues = {
  password: "",
  passwordConfirmation: "",
};

let schema = Yup.object().shape({
  password: Yup.string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las constraseñas deben coincidir")
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
});

function ResetPassword() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const code = query.get("token");
  // console.log(code);

  let history = useHistory();
  const alert = useContext(AlertContext);
  return (
    <section className="reset-password__wrapper">
      {error && <Alert variant="error">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Container className="reset-password__container">
        <h2>Contraseña nueva</h2>
        <p>Ingresa la contraseña nueva para tu cuenta:</p>
        <span style={{ fontSize: ".9rem", color: "#777777" }}>
          Tu contraseña tiene que ser de mínimo 8 caracteres.
        </span>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            values.token = code;
            // console.log(values);
            const response = await resetPassword(values);
            // console.log(response);
            if (response.errors && response.errors.length > 0) {
              // alert.error("hello");
              switch (response.errors[0].message) {
                case "Token is either invalid or has expired.":
                  alert.error(
                    "Token es inválido o ya expiró. Realiza de nuevo tu solicitud."
                  );
                  resetForm({
                    password: "",
                    passwordConfirmation: "",
                  });
                  setTimeout(() => {
                    history.push("/forgot-password");
                  }, 3000);
                  break;

                default:
                  alert.error(response.errors[0].message);
                  break;
              }
            } else if (
              response.token &&
              response.message === "Password reset successfully."
            ) {
              alert.success(
                "Tu contraseña ha sido cambiada. En unos segundos serás redirigido a la página de inicio de sesión."
              );
              resetForm({
                password: "",
                passwordConfirmation: "",
              });
              setTimeout(() => {
                setSuccess("");
                history.push("/login");
              }, 2000);
            }
            // if (response.error?.status === 403) {
            //   setError("Error en la solicitud. Por favor intenta de nuevo");
            //   setTimeout(() => {
            //     setError("");
            //   }, 3000);
            //   resetForm({
            //     password: "",
            //     passwordConfirmation: "",
            //   });
            //   return;
            // } else if (
            //   response.error?.status === 400 &&
            //   response.error?.message === "Incorrect code provided"
            // ) {
            //   setError(
            //     "El código del cambio ya se ha utilizado, por favor solicita uno nuevo"
            //   );
            //   setTimeout(() => {
            //     setError("");
            //     history.push("/forgot-password");
            //   }, 3000);
            //   resetForm({
            //     password: "",
            //     passwordConfirmation: "",
            //   });
            // } else if (response.jwt) {
            //   setSuccess(
            //     "Tu contraseña ha sido cambiada. En unos segundos serás redirigido a la página de inicio de sesión."
            //   );
            //   resetForm({
            //     password: "",
            //     passwordConfirmation: "",
            //   });
            //   setTimeout(() => {
            //     setSuccess("");
            //     history.push("/login");
            //   }, 4000);
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group className="form-group" controlId="passwordInput">
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Escribe tu nueva contraseña"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={errors.password}
                  />
                  <div className="error-feedback">
                    {touched.password && errors.password}
                  </div>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="passwordConfirmationInput"
                >
                  <Form.Control
                    name="passwordConfirmation"
                    type="password"
                    placeholder="Repite la contraseña"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirmation}
                    isInvalid={errors.passwordConfirmation}
                  />
                  <div className="error-feedback">
                    {touched.passwordConfirmation &&
                      errors.passwordConfirmation}
                  </div>
                </Form.Group>
              </Row>
              <Button
                variant="primary"
                type="submit"
                className=""
                disabled={isSubmitting}
              >
                {!isSubmitting && "Cambiar contraseña"}
                {isSubmitting && "Cargando..."}
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </section>
  );
}

export default ResetPassword;
