import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

// Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Context
import AlertContext from "../context/AlertContext";

// API
import { getBrandProducts } from "../api/product";
import { getBrand } from "../api/brand";
import { getWatchBands } from "../api/watch-band";
import { getWatchGenres } from "../api/genre";
import { getWatchTypes } from "../api/type";
import { getWatchCaliber } from "../api/caliber";
import { getWatchConditions } from "../api/condition";

// Custom Components
import ProductCard from "../components/ProductCard/ProductCard";

// Custom styles
import "../assets/scss/pages/brand.styles.scss";

// Util
import { toggleClass } from "../utils/toggleClass";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";

export default function Brand() {
  const { slug } = useParams();
  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [brand, setBrand] = useState(null);
  const [loadingBrands, setLoadingBrands] = useState(true);

  const [watchBands, setWatchBands] = useState([]);
  const [loadingWatchBands, setLoadingWatchBands] = useState(true);
  const [watchGenres, setWatchGenres] = useState([]);
  const [loadingWatchGenres, setLoadingWatchGenres] = useState(true);
  const [watchTypes, setWatchTypes] = useState([]);
  const [loadingWatchTypes, setLoadingWatchTypes] = useState(true);
  const [watchConditions, setWatchConditions] = useState([]);
  const [loadingWatchConditions, setLoadingWatchConditions] = useState(true);

  const [watchCalibers, setWatchCalibers] = useState([]);
  const [loadingWatchCalibers, setLoadingWatchCalibers] = useState(true);

  const alert = useContext(AlertContext);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getWatchGenres();
      // if (response !== null) {
      //   // for (let index = 0; index < response.data.length; index++) {
      //   //   delete response.data[index].attributes.createdAt;
      //   //   delete response.data[index].attributes.publishedAt;
      //   //   delete response.data[index].attributes.updatedAt;
      //   // }
      //   setWatchGenres(response.docs);
      //   setLoadingWatchGenres(false);
      // }
      // setLoadingWatchGenres(false);
      if (response?.error) {
        console.error("Error fetching watch genres:", response.message);
        alert.error(
          "Hubo un error al traer información de géneros. Por favor, intenta más tarde."
        );
      } else {
        setWatchGenres(response.docs);
      }
      setLoadingWatchGenres(false);
      // initFilters();
      // initFilters();
      // if (query.platform) {
      // }
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const response = await getWatchTypes();
      // if (response !== null) {
      //   setLoadingWatchTypes(false);
      // }
      if (response?.error) {
        console.error("Error fetching watch types:", response.message);
        alert.error(
          "Hubo un error al traer información de tipos. Por favor, intenta más tarde."
        );
      } else {
        setWatchTypes(response.docs);
        setLoadingWatchTypes(false);
      }
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const response = await getWatchCaliber();
      if (response?.error) {
        console.error("Error fetching watch calibers:", response.message);
        alert.error(
          "Hubo un error al traer información de calibres. Por favor, intenta más tarde."
        );
      } else {
        setWatchCalibers(response.docs);
      }
      setLoadingWatchCalibers(false);
      // if (response !== null) {
      //   setLoadingWatchCalibers(false);
      // }
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getWatchBands();
      if (response?.error) {
        console.error("Error fetching watch bands:", response.message);
        alert.error(
          "Hubo un error al traer información de bandas. Por favor, intenta más tarde."
        );
      } else {
        setWatchBands(response.docs);
      }
      setLoadingWatchBands(false);
      // if (response !== null) {
      //   // for (let index = 0; index < response.data.length; index++) {
      //   //   delete response.data[index].attributes.createdAt;
      //   //   delete response.data[index].attributes.publishedAt;
      //   //   delete response.data[index].attributes.updatedAt;
      //   // }
      //   setLoadingWatchBands(false);
      // }
      // initFilters();
      // initFilters();
      // if (query.platform) {
      // }
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getBrandProducts(slug);
      // if (response !== null) {
      //   // for (let index = 0; index < response.data.length; index++) {
      //   //   delete response.data[index].attributes.createdAt;
      //   //   delete response.data[index].attributes.publishedAt;
      //   //   delete response.data[index].attributes.updatedAt;
      //   //   delete response.data[index].attributes.caratula;
      //   //   delete response.data[index].attributes.carcasa;
      //   //   delete response.data[index].attributes.condicion;
      //   //   delete response.data[index].attributes.correa;
      //   //   delete response.data[index].attributes.descripcion;
      //   //   delete response.data[index].attributes.eta;
      //   //   delete response.data[index].attributes.informacionGeneral;
      //   //   delete response.data[index].attributes.genero.data.attributes
      //   //     .createdAt;
      //   //   delete response.data[index].attributes.genero.data.attributes
      //   //     .publishedAt;
      //   //   delete response.data[index].attributes.genero.data.attributes
      //   //     .updatedAt;
      //   //   delete response.data[index].attributes.marca.data.attributes
      //   //     .createdAt;
      //   //   delete response.data[index].attributes.marca.data.attributes
      //   //     .publishedAt;
      //   //   delete response.data[index].attributes.marca.data.attributes
      //   //     .updatedAt;
      //   //   delete response.data[index].attributes.tipoCorrea.data.attributes
      //   //     .createdAt;
      //   //   delete response.data[index].attributes.tipoCorrea.data.attributes
      //   //     .publishedAt;
      //   //   delete response.data[index].attributes.tipoCorrea.data.attributes
      //   //     .updatedAt;
      //   // }
      //   console.log(response);
      //   setLoadingProducts(false);
      // }
      if (response?.error) {
        console.error(
          "Error fetching watch brands' products:",
          response.message
        );
        alert.error(
          "Hubo un error al traer información de products para esta marca. Por favor, intenta más tarde."
        );
      } else {
        setProducts(response.docs);
      }
      setLoadingProducts(false);
      // if (query.platform) {
      // }
    })(slug);
  }, [slug]);

  useEffect(() => {
    // setLoading(true);
    (async () => {
      const response = await getBrand(slug);
      if (response?.error) {
        console.error("Error fetching brand:", response.message);
        alert.error(
          "Hubo un error al traer información de marca. Por favor, intenta más tarde."
        );
      } else {
        setBrand(response.docs);
      }
      setLoadingBrands(false);
      // if (response !== null) {
      //   // for (let index = 0; index < response.data.length; index++) {
      //   //   delete response.data[index].attributes.createdAt;
      //   //   delete response.data[index].attributes.publishedAt;
      //   //   delete response.data[index].attributes.updatedAt;
      //   //   delete response.data[index].attributes.imagen;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .alternativeText;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .caption;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .createdAt;
      //   //   delete response.data[index].attributes.portada.data.attributes.ext;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .formats;
      //   //   delete response.data[index].attributes.portada.data.attributes.hash;
      //   //   delete response.data[index].attributes.portada.data.attributes.height;
      //   //   delete response.data[index].attributes.portada.data.attributes.mime;
      //   //   delete response.data[index].attributes.portada.data.attributes.name;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .previewUrl;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .provider;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .provider_metadata;
      //   //   delete response.data[index].attributes.portada.data.attributes.size;
      //   //   delete response.data[index].attributes.portada.data.attributes
      //   //     .updatedAt;
      //   //   delete response.data[index].attributes.portada.data.attributes.width;
      //   // }
      //   setBrand(response.docs);
      //   console.log(response.docs);
      //   setLoadingBrands(false);
      // }
      // if (query.platform) {
      // }
    })(slug);
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getWatchConditions();
      if (response?.error) {
        console.error("Error fetching watch conditions:", response.message);
        alert.error(
          "Hubo un error al traer información de condiciones. Por favor, intenta más tarde."
        );
      } else {
        setWatchConditions(response.docs);
      }
      setLoadingWatchConditions(false);
    })();
  }, [slug]);

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(".filters");

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = products?.data.slice(indexOfFirstPost, indexOfLastPost);

  const filterTypeFunction = (text) => {
    // console.log(text.charAt(0).toUpperCase() + text.slice(1));
    const filter = products?.data.filter(
      (product) =>
        product.attributes.tipo?.data?.attributes.nombre ===
        text.charAt(0).toUpperCase() + text.slice(1)
    );
    setFilteredProducts(filter);
  };

  const filterCaliberFunction = (text) => {
    const filter = products?.data.filter(
      (product) =>
        product.attributes.calibre?.data?.attributes.nombre ===
        text.charAt(0).toUpperCase() + text.slice(1)
    );
    setFilteredProducts(filter);
  };

  const filterGenreFunction = (text) => {
    const filter = products?.data.filter(
      (product) => product.attributes.genero.data.attributes.nombre === text
    );
    setFilteredProducts(filter);
  };

  const filterFunction = (text) => {
    const filter = products?.data.filter(
      (product) => product.attributes.tipoCorrea.data.attributes.nombre === text
    );
    setFilteredProducts(filter);
  };

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      genero: {
        hombre: false,
        Mujer: false,
        unisex: false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
      condicion: {
        seminuevo: false,
        nuevo: false,
      },
    },
  };

  const [testinState, setTestingState] = useState(state);
  const [categories, setCategories] = useState([]);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      genero: [],
      tipo: [],
      condicion: [],
    };
    const { calibre, genero, correa, condicion, tipo, tipoCorrea } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let generoKey in genero) {
      if (genero[generoKey]) collectedTrueKeys.genero.push(generoKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    for (let condicionKey in condicion) {
      if (condicion[condicionKey])
        collectedTrueKeys.condicion.push(condicionKey);
    }
    return collectedTrueKeys;
  };

  const multiPropsFilter = (originalProducts, filters) => {
    const filterKeys = Object.keys(filters);
    // console.log(filterKeys);
    return originalProducts?.filter((product) => {
      return filterKeys.every((key) => {
        // console.log("key", key);
        if (!filters[key].length) return true;
        return filters[key].includes(product[key].nombre);
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(products, filteredCollected());
    return filteredProducts2;
  };

  useEffect(() => {
    initDropdownToggle();
  }, [slug]);
  // console.log(products);
  // if (loading) {
  //   return (
  //     <section className="brand-catalogue">
  //       <Container>
  //         <p>Cargando...</p>
  //       </Container>
  //     </section>
  //   );
  // }
  return (
    <section className="brand-catalogue">
      <section className="products col-12">
        <div className="products__container">
          <div
            className="products__brand__header"
            style={{
              backgroundImage: `url(${
                !loadingProducts
                  ? process.env.REACT_APP_API_URL + brand[0]?.portada?.url
                  : ""
              })`,
            }}
          ></div>
          <div className="container-fluid">
            <Row>
              <div className="filters col-12 col-lg-3">
                <div className="filters__container">
                  <div className="filters__header">
                    <img
                      src={FilterIcon}
                      alt="Filtros"
                      className="filters__icon"
                    />
                    <p className="filters__title">Filtros</p>
                    <span
                      className="d-block d-lg-none ms-3"
                      style={{ fontSize: ".7rem" }}
                    >
                      Haz clic para expandir filtros
                    </span>
                  </div>
                  <div className="filters__filter-container">
                    <div
                      className="filters__filter filters__filter--clear filter__item"
                      data-filter="all"
                    >
                      <div className="filters__filter__content">
                        <p
                          className="filters__filter__name filters__filter__name--small"
                          onClick={() => {
                            const selectedFilter = document.querySelectorAll(
                              ".filters__filter__dropdown-menu__item__name"
                            );
                            selectedFilter.forEach((filter) =>
                              filter.classList.remove("active")
                            );
                            setFilteredProducts([]);
                            setCategories([]);
                            setTestingState(state);
                          }}
                        >
                          Limpiar filtros <i className="far fa-times"></i>
                        </p>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Correa</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchBands?.map((watchBand, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={watchBand.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(
                                    watchBand.nombre,
                                    // .split(" ")
                                    // .join("_"),
                                    "tipoCorrea"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  // const filterMenu =
                                  //   document.querySelector(".filter__item");
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");
                                  // setProducts(
                                  //   products?.data.filter(
                                  //     (product) =>
                                  //       product.attributes.tipoCorrea.data.attributes
                                  //         .nombre === watchBand.attributes.nombre
                                  //   )
                                  // );
                                  if (
                                    categories.includes(
                                      watchBand.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          watchBand.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      watchBand.nombre.toLowerCase(),
                                    ]);
                                  }
                                  // if (
                                  //   filteredProducts.length === 0 &&
                                  //   category === ""
                                  // ) {
                                  //   filterFunction(watchBand.attributes.nombre);
                                  //   setCategory(watchBand.attributes.nombre);
                                  // } else {
                                  //   setFilteredProducts([]);
                                  //   setCategory("");
                                  // }
                                }}
                              >
                                <p
                                  className="filters__filter__dropdown-menu__item__name"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {watchBand.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Género</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchGenres?.map((watchGenre, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={watchGenre.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  // console.log(e.target);
                                  allFilterClickListener(
                                    watchGenre.nombre,
                                    "genero"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");
                                  if (
                                    categories.includes(
                                      watchGenre.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          watchGenre.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      watchGenre.nombre.toLowerCase(),
                                    ]);
                                  }
                                  // if (
                                  //   filteredProducts.length === 0 &&
                                  //   category === ""
                                  // ) {
                                  //   filterGenreFunction(watchGenre.attributes.nombre);
                                  //   setCategory(watchGenre.attributes.nombre);
                                  // } else {
                                  //   filterGenreFunction([]);
                                  //   setCategory("");
                                  // }
                                  // console.log(
                                  //   products?.filter(
                                  //     (product) =>
                                  //       product.attributes.tipoCorrea.data.attributes
                                  //         .nombre === watchGenre.attributes.nombre
                                  //   )
                                  // );
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name text-capitalize">
                                  {watchGenre.nombre}
                                </p>
                              </li>
                            ))}
                            {/* <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="hombre"
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Hombre
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="mujer"
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Mujer
                        </p>
                      </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Condición</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchConditions?.map((watchCondition, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={watchCondition.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  // console.log(e.target);
                                  allFilterClickListener(
                                    watchCondition.nombre,
                                    "condicion"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");
                                  if (
                                    categories.includes(
                                      watchCondition.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          watchCondition.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      watchCondition.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name text-capitalize">
                                  {watchCondition.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Tipo</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchTypes?.map((type, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={type.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(type.nombre, "tipo");
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");

                                  if (
                                    categories.includes(
                                      type.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !== type.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      type.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {type.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Calibre</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {/* <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="automático"
                        onClick={(e) => {
                          allFilterClickListener("Automático", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("automático")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "automático"
                              )
                            );
                          } else {
                            setCategories([...categories, "automático"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filtersDropdownMenu.classList.remove("active");
                          //   filterCaliberFunction("automático");
                          //   setCategory("automático");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Automático
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="cuarzo"
                        onClick={(e) => {
                          allFilterClickListener("Cuarzo", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("cuarzo")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "cuarzo"
                              )
                            );
                          } else {
                            setCategories([...categories, "cuarzo"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterCaliberFunction("cuarzo");
                          //   setCategory("cuarzo");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Cuarzo
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="cuerda"
                        onClick={(e) => {
                          allFilterClickListener("Cuerda", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("cuerda")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "cuerda"
                              )
                            );
                          } else {
                            setCategories([...categories, "cuerda"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterCaliberFunction("cuerda");
                          //   setCategory("cuerda");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Cuerda
                        </p>
                      </li> */}
                            {watchCalibers?.map((type, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={type.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(
                                    type.nombre,
                                    "calibre"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");

                                  if (
                                    categories.includes(
                                      type.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !== type.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      type.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {type.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brand-catalogue__products col-12 col-lg-9">
                <Row>
                  {loadingProducts ? (
                    <p className="pt-3">Cargando productos...</p>
                  ) : products?.length > 0 && categories.length === 0 ? (
                    // && categories.length === 0
                    products?.map((product, key) => (
                      <ProductCard
                        product={product}
                        key={key}
                        loading={loadingProducts}
                      />
                    ))
                  ) : null}
                  {products?.length === 0 && categories.length === 0 ? (
                    <p className="py-3">
                      No se encontraron relojes de esta marca.
                    </p>
                  ) : null}
                  {!loadingProducts && categories.length > 0
                    ? searchProducts()
                        .slice(indexOfFirstPost, indexOfLastPost)
                        .map((product, key) => (
                          <ProductCard
                            product={product}
                            key={key}
                            loading={loadingProducts}
                          />
                        ))
                    : null}
                  {!loadingProducts &&
                  categories.length > 0 &&
                  searchProducts().length === 0 ? (
                    <p className="py-3">
                      No se encontraron relojes con los filtros seleccionados.
                    </p>
                  ) : null}

                  {/* {filteredProducts?.length > 0 &&
                    filteredProducts?.map((product, key) => (
                      <ProductCard product={product} key={key} />
                    ))}

                  {filteredProducts?.length == 0 &&
                    category === "" &&
                    products?.data.length > 0 &&
                    currentPosts?.map((product, key) => (
                      <ProductCard
                        product={product}
                        key={key}
                        loading={loading}
                      />
                    ))}

                  {filteredProducts?.length === 0 && category !== "" && (
                    <p className="pt-3">No hay productos</p>
                  )} */}
                  {/* {products?.data.map((product, key) => (
                    <ProductCard product={product} key={key} />
                  ))} */}
                </Row>
              </div>
            </Row>
          </div>
        </div>
      </section>
    </section>
  );
}
