// TO-DO: Set where in all api calls for filters so only matched with genre are rendered,
//        'cause no point of showing data that doesnt exist in specific genre

import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

// Bootstrap Components
import Row from "react-bootstrap/Row";

// Context
import AlertContext from "../context/AlertContext";

// API
import { getGenreProducts } from "../api/product";
import { getWatchBands } from "../api/watch-band";
import { getWatchGenres, getGenre } from "../api/genre";
import { getWatchTypes } from "../api/type";
import { getBrands } from "../api/brand";
import { getWatchCaliber } from "../api/caliber";
import { getWatchConditions } from "../api/condition";

// Custom Components
import ProductCard from "../components/ProductCard/ProductCard";

// Custom styles
import "../assets/scss/pages/genre.scss";

// Util
import { toggleClass } from "../utils/toggleClass";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";

export default function Genre() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [genre, setGenre] = useState(null);

  const alert = useContext(AlertContext);

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      marca: {
        Bulova: false,
        Citizen: false,
        IWC: false,
        Longines: false,
        Mido: false,
        Omega: false,
        "Porsche Design": false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
      condicion: {
        seminuevo: false,
        nuevo: false,
      },
    },
  };

  const [watchBands, setWatchBands] = useState([]);
  const [loadingWatchBands, setLoadingWatchBands] = useState(true);
  const [watchGenres, setWatchGenres] = useState([]);
  const [loadingWatchGenres, setLoadingWatchGenres] = useState(true);
  const [testinState, setTestingState] = useState(state);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [watchTypes, setWatchTypes] = useState([]);
  const [loadingWatchTypes, setLoadingWatchTypes] = useState(true);
  const [watchCalibers, setWatchCalibers] = useState([]);
  const [loadingWatchCalibers, setLoadingWatchCalibers] = useState(true);
  const [loadingWatchConditions, setLoadingWatchConditions] = useState(true);
  const [watchConditions, setWatchConditions] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getGenreProducts(slug);
      if (response?.error) {
        console.error("Error fetching genre productos:", response.message);
        alert.error(
          "Hubo un error al traer productos de este género. Por favor, intenta más tarde."
        );
      } else {
        setProducts(response.docs);
      }
      setLoading(false);
      // if (query.platform) {
      // }
    })(slug);
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getGenre(slug);
      if (response?.error) {
        console.error("Error fetching genre:", response.message);
        alert.error(
          "Hubo un error al traer información de este género. Por favor, intenta más tarde."
        );
      } else {
        setGenre(response.docs[0]);
      }
      setLoadingWatchGenres(false);
      // if (query.platform) {
      // }
    })(slug);
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getWatchBands();
      if (response?.error) {
        console.error("Error fetching watch bands:", response.message);
        alert.error(
          "Hubo un error al traer información de correas. Por favor, intenta más tarde."
        );
      } else {
        setWatchBands(response.docs);
      }
      setLoadingWatchBands(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getWatchConditions();
      if (response?.error) {
        console.error("Error fetching watch conditions:", response.message);
        alert.error(
          "Hubo un error al traer información de condiciones. Por favor, intenta más tarde."
        );
      } else {
        setWatchConditions(response.docs);
      }
      setLoadingWatchConditions(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      const response = await getBrands();
      if (response?.error) {
        console.error("Error fetching watch brands.", response.message);
        alert.error(
          "Hubo un error al traer información de marcas. Por favor, intenta más tarde."
        );
      } else {
        setBrands(response.docs);
      }
      setLoadingBrands(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const response = await getWatchTypes();
      if (response?.error) {
        console.error("Error fetching watch types.", response.message);
        alert.error(
          "Hubo un error al traer información de tipos. Por favor, intenta más tarde."
        );
      } else {
        setWatchTypes(response.docs);
      }
      setLoadingWatchTypes(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const response = await getWatchCaliber();
      if (response?.error) {
        console.error("Error fetching watch caliber.", response.message);
        alert.error(
          "Hubo un error al traer información de calibres. Por favor, intenta más tarde."
        );
      } else {
        setWatchCalibers(response.docs);
      }
      setLoadingWatchCalibers(false);
    })();
  }, [slug]);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      marca: [],
      tipo: [],
      condicion: [],
    };
    const { calibre, genero, condicion, marca, tipo, tipoCorrea } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let marcaKey in marca) {
      if (marca[marcaKey]) collectedTrueKeys.marca.push(marcaKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    for (let condicionKey in condicion) {
      if (condicion[condicionKey])
        collectedTrueKeys.condicion.push(condicionKey);
    }
    return collectedTrueKeys;
  };

  // const multiPropsFilter = (products, filters) => {
  //   const filterKeys = Object.keys(filters);
  //   return products?.data.filter((product) => {
  //     return filterKeys.every((key) => {
  //       if (!filters[key].length) return true;
  //       return filters[key].includes(
  //         product?.attributes[key].data.attributes.nombre
  //       );
  //     });
  //   });
  // };

  const multiPropsFilter = (originalProducts, filters) => {
    const filterKeys = Object.keys(filters);
    // console.log(filterKeys);
    return originalProducts?.filter((product) => {
      return filterKeys.every((key) => {
        // console.log("key", key);
        if (!filters[key].length) return true;
        return filters[key].includes(product[key].nombre);
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(products, filteredCollected());
    return filteredProducts2;
  };

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(".filters");

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };
  useEffect(() => {
    initDropdownToggle();
  }, []);

  // console.log(genre);
  // if (loading) {
  //   <section className="brand-catalogue">
  //     <Container>
  //       <p>Cargando...</p>
  //     </Container>
  //   </section>;
  // }
  return (
    <section className="genre-catalogue">
      <section className="products col-12">
        <div className="products__container">
          <div
            className="products__genre__header"
            style={{
              backgroundImage: `linear-gradient(
                   rgba(0, 0, 0, 0.53),
                   rgba(0, 0, 0, 0.275)
                 ), url(${process.env.REACT_APP_API_URL}${genre?.portada?.url})`,
            }}
          >
            <h3 className="text-capitalize">{genre?.nombre}</h3>
          </div>
          <div className="products__product-container">
            {/* {!loading && data.allProductos?.length > 0 ? (
              data.allProductos.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))
            ) : (
              <p className="products__emtpy">Cargando productos...</p>
            )} */}
            <Row>
              <div className="filters col-12 col-lg-3">
                <div className="filters__container">
                  <div className="filters__header">
                    <img
                      src={FilterIcon}
                      alt="Filtros"
                      className="filters__icon"
                    />
                    <p className="filters__title">Filtros</p>
                    <span
                      className="d-block d-lg-none ms-3"
                      style={{ fontSize: ".7rem" }}
                    >
                      Haz clic para expandir filtros
                    </span>
                  </div>
                  <div className="filters__filter-container">
                    <div
                      className="filters__filter filters__filter--clear filter__item"
                      data-filter="all"
                    >
                      <div className="filters__filter__content">
                        <p
                          className="filters__filter__name filters__filter__name--small"
                          onClick={() => {
                            const selectedFilter = document.querySelectorAll(
                              ".filters__filter__dropdown-menu__item__name"
                            );
                            selectedFilter.forEach((filter) =>
                              filter.classList.remove("active")
                            );
                            setCategories([]);
                            setTestingState(state);
                          }}
                        >
                          Limpiar filtros <i className="far fa-times"></i>
                        </p>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Correa</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchBands?.map((watchBand, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={watchBand.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(
                                    watchBand.nombre,
                                    "tipoCorrea"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");
                                  if (
                                    categories.includes(
                                      watchBand.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          watchBand.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      watchBand.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name text-capitalize">
                                  {watchBand.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Marca</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {brands?.map((brand, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={brand.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  // console.log(e.target);
                                  allFilterClickListener(
                                    brand.nombre,
                                    // .toLowerCase()
                                    // .split(" ")
                                    // .join("_"),
                                    "marca"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");

                                  if (
                                    categories.includes(
                                      brand.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          brand.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      brand.nombre.toLowerCase(),
                                    ]);
                                  }

                                  // if (
                                  //   filteredProducts.length === 0 &&
                                  //   category === ""
                                  // ) {
                                  //   filterBrandFunction(brand.nombre);
                                  //   setCategory(brand.attributes.nombre);
                                  // } else {
                                  //   filterGenreFunction([]);
                                  //   setCategory("");
                                  // }
                                  // console.log(
                                  //   products?.filter(
                                  //     (product) =>
                                  //       product.attributes.tipoCorrea.data.attributes
                                  //         .nombre === brand.attributes.nombre
                                  //   )
                                  // );
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {brand.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Condición</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchConditions?.map((watchCondition, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={watchCondition.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  // console.log(e.target);
                                  allFilterClickListener(
                                    watchCondition.nombre,
                                    "condicion"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");
                                  if (
                                    categories.includes(
                                      watchCondition.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          watchCondition.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      watchCondition.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name text-capitalize">
                                  {watchCondition.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Tipo</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchTypes.map((type, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={type.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(type.nombre, "tipo");
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");

                                  if (
                                    categories.includes(
                                      type.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !== type.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      type.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {type.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Calibre</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchCalibers.map((type, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={type.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(
                                    type.nombre,
                                    "calibre"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");

                                  if (
                                    categories.includes(
                                      type.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !== type.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      type.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {type.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brand-catalogue__products col-12 col-lg-9">
                <Row>
                  {loading ? (
                    <p className="pt-3">Cargando productos...</p>
                  ) : products?.length > 0 && categories.length === 0 ? (
                    products?.map((product, key) => (
                      <ProductCard
                        product={product}
                        key={key}
                        loading={loading}
                      />
                    ))
                  ) : null}

                  {!loading && categories.length > 0
                    ? searchProducts().map((product, key) => (
                        <ProductCard
                          product={product}
                          key={key}
                          loading={loading}
                        />
                      ))
                    : null}
                  {!loading &&
                  categories.length > 0 &&
                  searchProducts().length === 0 ? (
                    <p className="py-3">
                      No se encontraron relojes con los filtros seleccionados.
                    </p>
                  ) : null}
                </Row>
              </div>
            </Row>
          </div>
        </div>
      </section>
    </section>
  );
}
