import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Styles
import "../assets/scss/pages/order.scss";

// API
import { getMeApi } from "../api/user";
import { getOrder } from "../api/order";

// Hooks
import useAuth from "../hooks/useAuth";

// Utils
import formatMoney from "../utils/formatMoney";

export default function Order() {
  const { id } = useParams();
  // const [user, setUser] = useState(undefined);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const { auth, logout, user } = useAuth();
  // useEffect(() => {
  //   (async () => {
  //     const response = await getMeApi(logout);
  //     setUser(response);
  //   })();
  // }, [auth]);

  useEffect(() => {
    (async () => {
      if (user && Object.keys(user || {})?.length > 0) {
        const response = await getOrder(id, logout);
        if (response?.docs !== null && response?.docs.length > 0) {
          setOrder(response.docs[0]);
          // console.log(response);
        } else setOrder([]);
        setLoading(false);
      }
    })();
  }, [user]);
  // console.log(order);

  if (loading) {
    return (
      <section className="order__wrapper">
        <Container className="order__container text-center">
          <p className="py-4">Cargando información de orden...</p>
        </Container>
      </section>
    );
  }
  return (
    <div className="order__wrapper">
      <Container className="order__container">
        <Link to="/profile" className="text-muted">
          Regresar
        </Link>
        <h2>Orden confirmada</h2>
        <p>
          <span>Id de orden: </span>
          <span>{order?.orderId}</span>
        </p>
        <p>
          <span>Id de pago: </span>
          <span>{order?.token}</span>
        </p>
        <p>
          <span>Cantidad de productos: </span>
          <span>{order?.orderItems.length}</span>
        </p>
        <div className="items">
          <h4 style={{ color: "red" }}>Lista de artículos:</h4>
          {order?.orderItems.map((item) => (
            <div className="order-item" key={item.id}>
              <p>
                <span>Producto: </span>
                <span>{item.product.nombre}</span>
              </p>
              <p>
                <span>Precio: </span>
                <span>{formatMoney(item.product.precio)} MXN</span>
              </p>
            </div>
          ))}
        </div>
        <h3>
          <span>Total: </span>
          <span>{formatMoney(order?.total)} MXN</span>
        </h3>
      </Container>
    </div>
  );
}
