import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { STRIPE_KEY, BASE_PATH } from "../utils/constants";

// API
import { getBidProduct } from "../api/bids";
import { getMeApi } from "../api/user";
import { postBid } from "../api/bids";
// import useAuth from "../hooks/useAuth";

// Contexts
import { useAuth } from "../context/AuthContext";
import AlertContext from "../context/AlertContext";

// Axios for sending email
import axios from "axios";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

// Custom Components
import { ProductCarousel } from "../components/Carousel/Carousel";
import { FacebookShare, WhatsAppShare } from "../components/ShareButton";
import CardSection from "../components/CardSection/CardSection";
import Timer from "react-compound-timer";
import moment from "moment-timezone";

// Utils
import { toggleClass } from "../utils/toggleClass";
import formatMoney from "../utils/formatMoney";
import useForm from "../utils/useForm";
import { v4 as uuidv4 } from "uuid";

// Custom styles
import "../assets/scss/pages/bidProduct.scss";

// Assets
import WatchIconGeneral from "../assets/images/watch-icon.svg";
import WatchIconCase from "../assets/images/watch-icon-case.svg";
import WatchIconPointer from "../assets/images/watch-icon-pointer.svg";
import WatchIconStrap from "../assets/images/watch-icon-strap.svg";
import ArrowIcon from "../assets/images/arrow-icon--red.svg";
import nationalLottery from "../assets/images/national-lottery.png";
import lockIconUrl from "../assets/images/lock-icon.svg";
import creditCard1Url from "../assets/images/credit-cards_visa.svg";
import creditCard2Url from "../assets/images/credit-cards_mastercard.svg";
import creditCard3Url from "../assets/images/credit-cards_amex.svg";

const stripeLib = loadStripe(`${STRIPE_KEY}`, {
  locale: "es-419",
});

function PaymentForm({
  auth,
  user,
  product,
  tickets,
  setTickets,
  paymentLoading,
  setPaymentLoading,
  purchasedTickets,
  logout,
  total,
  error: stripeError,
  setError,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();

  // console.log(product);

  const { inputs, handleChange, resetForm } = useForm({
    user: user?.username,
    ticket: 0,
  });

  async function handleSubmit(e) {
    setPaymentLoading(true);
    e.preventDefault();
    // console.log(
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    const orderId = uuidv4().slice(0, 7);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.user.email,
          name: user.user.fullName,
          phone: user.user.phoneNumber,
          // address: {
          //   city: "Aguascalientes",
          //   country: "MX",
          //   line1: "Aguascalientes",
          //   line2: "Aguascalientes",
          //   state: "Aguascalientes",
          // },
        },
      });
      // console.log(error);
      // if (error) {
      //   setError(error.message);
      //   setPaymentLoading(true);
      // }
      // tickets.forEach(function (ticket, index) {
      //   let x = {
      //     id:
      //       purchasedTickets.length === 0
      //         ? 1
      //         : purchasedTickets[purchasedTickets.length - 1].id + 1,
      //     ticket: ticket.ticket,
      //     usuario: user.user.fullName,
      //     emailUsuario: user.user.email,
      //     orderId,
      //     paymentIntent: paymentMethod.id,
      //   };
      //   // console.log(x);
      //   purchasedTickets.push(x);
      //   // console.log("its normal", purchasedTickets);
      //   // console.log(
      //   //   "its not normal",
      //   //   purchasedTickets.sort((a, b) => a.ticket - b.ticket)
      //   // );
      // });
      const formDataTemp = {
        // id: product?.id,
        // purchasedTickets,
        numberOfTickets: tickets.length,
        id: product.id,
        total,
        paymentIntent: paymentMethod.id,
        newTickets: tickets.map((ticket) => ({
          ticket: ticket.ticket,
          orderId: orderId,
        })),
      };
      // console.log(formDataTemp);
      const response = await postBid(product?.id, formDataTemp, auth.token);
      // console.log(response, "157");
      if (response.error) {
        // console.log(response.errors[0], "errors");
        // if (response.error.length >= 2) {
        //   setError(
        //     `Los boletos ${response.error.map(
        //       (error) => `${error.ticket}`
        //     )} ya no están disponibles`
        //   );
        // }
        // if (Array.isArray(response.error)) {
        //   if (response.error.length === 1) {
        //     setError(
        //       `El boleto ${response.error[0].ticket} ya no está disponible`
        //     );
        //   }
        //   if (response.error.length >= 2) {
        //     setError(
        //       `Los boletos ${response.error.map(
        //         (error) => `${error.ticket}`
        //       )} ya no están disponibles`
        //     );
        //   }
        // }
        if (response.errorCode === 500) {
          let countdown = 5; // Start countdown from 5 seconds

          setError(
            `Uno o varios tickets elegidos ya no se encuentran disponibles. Recargando en ${countdown}...`
          );

          const countdownInterval = setInterval(() => {
            countdown -= 1;
            setError(
              `Uno o varios tickets elegidos ya no se encuentran disponibles. Recargando en ${countdown}...`
            );

            if (countdown === 0) {
              clearInterval(countdownInterval); // Stop the countdown
              window.location.reload(); // Reload the page
            }
          }, 1000); // Update message every second
        } else {
          setError(response.error.message);
          setTimeout(() => {
            setError("");
            setTickets([]);
          }, 5000);
        }
        // setTickets
        // console.log("Hubo un error en tu solicitud de puja");
        setTimeout(() => {
          setError("");
          setTickets([]);
        }, 5000);
        setPaymentLoading(false);
      } else {
        // console.log(response);
        // setError(
        //   "Uno o varios tickets elegidos ya no se encuentran disponibles."
        // );
        // setTimeout(() => {
        //   setError("");
        //   setTickets([]);
        // }, 5000);
        // axios.post(
        //   `${BASE_PATH}/api/email`,
        //   {
        //     To: user.email,
        //     Subject: "Confirmación de compra de tickets",
        //     HtmlBody: `
        //     <html lang="es">

        //     <head>
        //       <meta charset="UTF-8" />
        //       <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        //       <link rel="preconnect" href="https://fonts.googleapis.com">
        //       <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        //       <link href="https://fonts.googleapis.com/css2?family=Epilogue:wght@500;600&family=Montserrat&display=swap"
        //         rel="stylesheet">
        //     </head>

        //     <body style="margin: 0; padding: 0; ">
        //       <div style="font-family: 'Montserrat', sans-serif; background-color: #F3F3F3; padding: 2rem 1rem; height: auto;">
        //         <div
        //           style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #0D314F; padding:0; overflow: hidden;">
        //           <div class="heading" style="background-color: #FBFBFB; padding: 2rem; text-align: center;">
        //           <img src="https://larelojeriair.com/static/media/logo.6c688721ca19b8134e9eb4c1cc34428c.svg"
        //             style="height: 60px;" alt="La Relojeríair">
        //           </div>
        //           <div style="padding: 1rem 1.5rem;">
        //             <h2
        //               style="text-transform: uppercase; font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000; text-align: center;">
        //               Confirmación de compra de ticket</h2>
        //             <div style="text-align: center;">
        //               <img src="https://larelojeriair.com/static/media/check-icon--green--sm.2689592d6d9a129756b67c8955b16f5c.svg"
        //                 alt="La Relojeríair" title="La Relojeríair" style="display: block; margin: 1rem auto;" width="80px"
        //                 height="auto" />
        //               <h3 style="margin-bottom: .5rem;">El cobro de tus tickets fue exitoso.</h3>
        //               <p style="margin-bottom: 1rem; margin-top: 0; color: #000;">Número de orden #${orderId}</p>
        //             </div>
        //             <h3 style="font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #bb2227;">
        //               Detalles de envío</h3>
        //             <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0;">
        //               <tr>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
        //                   <strong>Fecha:</strong>
        //                 </td>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
        //                   ${date}</td>
        //               </tr>
        //               <tr>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
        //                   <strong>Nombre:</strong>
        //                 </td>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
        //                   ${user.username}</td>
        //               </tr>
        //               <tr>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
        //                   <strong>Email:</strong>
        //                 </td>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
        //                   ${user.email}</td>
        //               </tr>
        //               <tr>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
        //                   <strong>Teléfono:</strong>
        //                 </td>
        //                 <td valign="top"
        //                   style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
        //                   ${user.phoneNumber}</td>
        //               </tr>
        //             </table>
        //             <h3 style="font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #bb2227">
        //               Tus tickets para la rifa del reloj ${
        //                 product?.nombreProducto
        //               }:</h3>
        //             <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">

        //               ${tickets.map(
        //                 (ticket) => `
        //               <p style="margin: 0; margin-right: 1rem; display: inline-block;">${ticket.ticket}</p>
        //               `
        //               )}
        //             </div>
        //             <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
        //               <h5 style="margin: 0; font-size: 1.1rem;">Total</h5>
        //               <p style="margin: 0; color: #bb2227; font-size: 1.4rem;">${formatMoney(
        //                 total
        //               )} MNX</p>
        //             </div>
        //           </div>
        //         </div>
        //         <div class="coupon"
        //           style="background: #0D314F; width: 90vw; margin: 1rem auto; color: #fff; padding: 2.3rem 1rem; text-align: center; box-sizing: border-box; ">
        //           <h3
        //             style="font-size: 1.7rem; margin: 0; line-height: 1.7px; font-family: 'Epilogue', sans-serif; line-height: 1;">
        //             Síguenos en
        //             facebook</h3>
        //           <a style="display: block; text-decoration: none; font-weight: bolder; font-size: 1.5rem; margin-top: 1rem; color: #fff;"
        //             href="https://www.facebook.com/La-Relojeria-Original-103481138576106" target="_blank">La Relojeríair</a>
        //         </div>
        //       </div>
        //     </body>

        //     </html>
        //     `,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${auth.token}`,
        //     },
        //   }
        // );
        history.push(`/ticket-confirmation/${orderId}`);
        // setBids(response.raffleEntries);
        setPaymentLoading(false);
        // resetForm({
        //   user: user.username,
        // });
        // setCount(
        //   response.raffleEntries[response.raffleEntries.length - 1].bid +
        //     product?.data.attributes.pujaSubsecuente
        // );
      }
      // setPaymentLoading(false);
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
    // tickets.forEach((ticket) =>
    //   // console.log(ticket)
    //   ({
    //     id: 1,
    //     ticket: ticket.ticket,
    //     usuario: "Rodrigo Zenteno",
    //   })
    // );
    // );
    // const newTickets = tickets?.forEach(function (ticket) {
    //   const x = {
    //     id: 1,
    //     ticket: ticket.ticket,
    //     usuario: "Rodrigo Zenteno",
    //   };
    //   // console.log(x);
    //   return x;
    // });
    // console.log(tickets, "tickets");
    // const newTickets = tickets.forEach((ticket) => ({
    //   id: 1,
    //   ticket: ticket.ticket,
    //   usuario: "Rodrigo Zenteno",
    // }));
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardSection />
      <button type="submit">
        {paymentLoading ? "Procesando pago..." : "Completar compra"}
      </button>
    </form>
  );
}

export default function BidIndividualProduct() {
  const [product, setProduct] = useState(null);
  const [bids, setBids] = useState([]);
  const [tickets, setTickets] = useState([]);
  // const [dateFromApi, setDateFromApi] = useState(null);
  const [count, setCount] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  // const [user, setUser] = useState({});
  const { auth, logout, user } = useAuth();

  const endDate = new Date(`${product?.fechaFinal}`);
  const [error, setError] = useState("");

  const alert = useContext(AlertContext);
  // console.log(user);

  // console.log(startDate.getFullYear());
  // const stripe = useStripe();
  // const elements = useElements();

  // console.log(auth);

  // useEffect(() => {
  //   // setLoading(true);
  //   if (Object.keys(auth) === 0) {
  //     return;
  //   } else {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       if (response) {
  //         setUser(response);
  //       }
  //       return;
  //       // setLoading(false);
  //     })();
  //   }
  // }, []);

  useEffect(() => {
    // console.log(user);
    (async () => {
      const token = auth?.token || null;
      const response = await getBidProduct(id, auth.token);

      if (response.error) {
        alert.error("Hubo un error al traer información de rifa.");
        // setDateFromApi(response.fechaFinal);
        setProduct({});
      } else {
        // setDateFromApi(response.fechaFinal);
        setProduct(response);
      }
      // console.log(response);
      setLoading(false);
      // setBids(response.data.attributes.raffleEntries);
      // setCount(response.data.pujaInicial);

      // if (query.platform) {
      // }
    })();
  }, [id, auth.token]);
  // useEffect(() => {
  //   // setLoading(true);
  //   (() => {
  //     // const response = await getAuctionProduct(id);
  //     setProduct(productData);
  //     // console.log(response);
  //     // setLoading(false);
  //     // setBids(productData.data.attributes.raffleEntries);
  //     setCount(productData.data.attributes.pujaInicial);
  //     setDateFromApi(productData.data.attributes.fechaFinal);

  //     // if (query.platform) {
  //     // }
  //   })(id);
  // }, [id]);

  // const initDropdownPay = () => {
  //   // if (tickets?.length === 0) {
  //   //   document
  //   //     .querySelector(".collapsible")
  //   //     .addEventListener("click", (event) => {
  //   //       document.querySelector(".content").classList.toggle("active");
  //   //       var content = document.querySelector(".content");
  //   //       console.log("hola");
  //   //       if (window.innerWidth > 991) {
  //   //         if (content.style.height === "210px") {
  //   //           content.style.height = "0";
  //   //           console.log("a 0");
  //   //         } else {
  //   //           content.style.height = "210px";
  //   //           content.style.display = "block";
  //   //           console.log("a 210px");
  //   //         }
  //   //       } else {
  //   //         if (content.style.height === "250px") {
  //   //           content.style.height = "0";
  //   //         } else {
  //   //           content.style.height = "250px";
  //   //         }
  //   //       }
  //   //     });
  //   // }
  //   if (!loading) {
  //     const button = document.querySelector(".collapsible");
  //     button.addEventListener("click", (event) => {
  //       document.querySelector(".content").classList.toggle("active");
  //       var content = document.querySelector(".content");
  //       console.log("hola");
  //       if (window.innerWidth > 991) {
  //         if (content.style.height === "auto") {
  //           content.style.height = "0";
  //           console.log("a 0");
  //         } else {
  //           content.style.minHeight = "auto";
  //           // content.style.display = "block";
  //           console.log("a 210px");
  //         }
  //       } else {
  //         if (content.style.height === "250px") {
  //           content.style.height = "0";
  //         } else {
  //           content.style.height = "250px";
  //         }
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   initDropdownPay();
  // }, []);

  // const counterValue = () => {
  //   // const dateFromApi = product.data.attributes.fechaFinal;
  //   if (dateFromApi !== null) {
  //     // const dateFromApi = product.data.attributes?.fechaFinal;
  //     const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
  //     const added = createdAt.clone();
  //     const now = moment.tz("America/Mexico_City");
  //     const duration = added.diff(now, "milliseconds");
  //     return duration;
  //   }
  //   // const createdAt = moment.tz(
  //   //   product?.data.attributes.fechaFinal,
  //   //   "America/Mexico_City"
  //   // );
  //   // const added = createdAt.clone();
  //   // const now = moment.tz("America/Mexico_City");
  //   // setDuration(added.diff(now, "milliseconds"));
  // };

  const initDropdowns = () => {
    // if (!loading) {
    const sectionContainer = document.querySelector(".product__details");
    const sections = Array.from(sectionContainer.children);

    sections.forEach((section) => {
      section.addEventListener("click", (event) => {
        const header = event.target.closest(
          ".product__details__section__header"
        );
        // console.log(header);
        header !== null && header !== undefined && toggleClass(section, "open");
      });
    });
    // }
  };

  useEffect(() => {
    if (!loading && Object.keys(product).length > 0) {
      initDropdowns();
    }
  }, [loading, product]);

  // useEffect(() => {
  //   // if (dateFromApi !== null) {
  //   //   const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
  //   //   const added = createdAt.clone();
  //   //   const now = moment.tz("America/Mexico_City");
  //   //   const duration = added.diff(now, "milliseconds");
  //   //   return duration;
  //   // }
  //   const createdAt = moment.tz(
  //     product.data.attributes?.fechaFinal,
  //     "America/Mexico_City"
  //   );
  //   const added = createdAt.clone();
  //   const now = moment.tz("America/Mexico_City");
  //   setDuration(added.diff(now, "milliseconds"));
  // }, [product, dateFromApi]);
  // console.log(duration);
  // useEffect(() => {
  //   if (bids.length === 0) {
  //     setCount(product?.pujaInicial);
  //   } else {
  //     setCount(
  //       bids[bids.length - 1].bid + product?..pujaSubsecuente
  //     );
  //   }
  // }, [product, bids]);
  // if (loading) {
  //   return (
  //     <section className="product">
  //       <div className="product__container">
  //         <p className="mx-auto my-4">Cargando reloj ⌛️...</p>
  //       </div>
  //     </section>
  //   );
  // }
  if (loading) {
    return (
      <div className="raffles__wrapper">
        <Container className="text-center">
          <p className="py-4">Cargando reloj ⌛️...</p>
        </Container>
      </div>
    );
  }

  return (
    <section className="product">
      <div className={`overlay ${paymentLoading ? "show" : null}`}></div>
      <div className="product__container">
        {!loading && Object.keys(product).length > 0 && (
          <>
            <div className="product__tickets__carousel-container col-12  col-lg-6">
              {product?.imagenes?.length > 0 && (
                <ProductCarousel
                  productName={product?.nombre}
                  productImages={product.imagenes}
                />
              )}
            </div>
            <div className="product__content col-12  col-lg-6">
              <div className="product__ticket__content-holder">
                <div className="product__ticket__header__status">
                  {new Date(`${product?.fechaFinal}`) > new Date()
                    ? "¡Compra ya tu boleto!"
                    : "Rifa cerrada"}
                </div>
                <div className="product__ticket__header">
                  <p>{product?.marca?.nombre}</p>
                  {/*{newModel && <p className="product__newModel">Nuevo modelo</p>} */}
                </div>
                <p className="product__name">{product?.nombreProducto}</p>
                <div className="product__ticket__prices">
                  <p>
                    Precio por boleto:{" "}
                    <span style={{ color: "#19B391" }}>
                      {" "}
                      {formatMoney(product?.precioBoleto)}
                    </span>
                  </p>
                  <p className="ms-4">
                    Valor del reloj:{" "}
                    <span> {formatMoney(product?.precio)} </span>
                  </p>
                </div>
                <h4 className="my-2">
                  Boletos restantes:{" "}
                  {product?.boletosTotales - product?.boletosPagados} boletos
                </h4>
                {/* <p className="raffle-product-card__timer mt-2">
                  {dateFromApi !== null && counterValue() > 0 && (
                    <Timer initialTime={counterValue()} direction="backward">
                      {() => (
                        <>
                          <Timer.Days /> días <Timer.Hours /> horas{" "}
                          <Timer.Minutes /> minutos <Timer.Seconds /> segundos{" "}
                        </>
                      )}
                    </Timer>
                  )}
                </p>
                {counterValue() < 0 && (
                  <p style={{ color: "#E10009", fontWeight: "bolder" }}>
                    La rifa ha finalizado
                  </p>
                )} */}
                {product?.boletosPagados === product?.boletosTotales ? (
                  <p style={{ color: "#E10009", fontWeight: "bolder" }}>
                    La rifa ha finalizado
                  </p>
                ) : null}
                <div className="product__national-lottery">
                  {product?.linkLoteriaNacional === null ||
                  product?.linkLoteriaNacional === "" ? (
                    <p className="national-lottery-link text-center">
                      <img src={nationalLottery} alt="La Relojeríair" /> Espera
                      el link del sitio
                    </p>
                  ) : (
                    <a
                      href={product?.linkLoteriaNacional}
                      target="_blank"
                      rel="noreferrer"
                      className="national-lottery-link"
                    >
                      <img src={nationalLottery} alt="La Relojeríair" />
                      Link directo a Lotería Nacional
                    </a>
                  )}
                </div>
                {product?.ganador !== null && product?.ganador !== "" && (
                  <div className="product__winner">
                    <p>
                      🎉 Ganador: 🎉 <span>{product?.ganador}</span>
                    </p>
                    <a
                      href="https://wa.me/524495457748"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className=" fab fa-whatsapp"></i> El ganador favor de
                      comunicarse por WhatsApp
                    </a>
                  </div>
                )}
                <p className="product__description">{product?.descripcion}</p>

                {new Date() < endDate ? (
                  <div className="product__ticket__container">
                    <h4>Boletos disponibles:</h4>
                    <div className="product__ticket__container__tickets">
                      {/* <div
                      className={`product__ticket__container__tickets-overlay ${
                        counterValue() < 0 ? "show" : null
                      }`}
                    ></div> */}
                      <ol className="seats" type="A">
                        {[...Array(product?.boletosTotales)].map(
                          (star, index) => {
                            // if index + 1 is in product?.data.purchasedTickets, return disabled
                            const totalDigits =
                              product?.boletosTotales >= 1000
                                ? 3
                                : product?.boletosTotales >= 100
                                ? 2
                                : 1;
                            const formattedNumber = index
                              .toString()
                              .padStart(totalDigits, "0");
                            return product?.purchasedTickets?.some(
                              (ticket) => ticket.ticket === index
                            ) ? (
                              <li className="seat">
                                <input
                                  type="checkbox"
                                  id={index + 1}
                                  disabled={true}
                                />
                                <label htmlFor={index + 1}>X</label>
                              </li>
                            ) : (
                              <li className="seat">
                                <input
                                  type="checkbox"
                                  id={index + 1}
                                  onClick={() => {
                                    if (
                                      tickets.some(
                                        (ticket) => ticket.ticket === index
                                      )
                                    ) {
                                      setTickets([
                                        ...tickets.filter(
                                          (ticket) => ticket.ticket !== index
                                        ),
                                      ]);
                                    } else {
                                      setTickets([
                                        ...tickets,
                                        { ticket: index },
                                      ]);
                                    }
                                  }}
                                />
                                <label htmlFor={index + 1}>
                                  {/* {index.toString().length < 2
                                    ? `0${index}`
                                    : index.toString().length === 2
                                    ? index.toString().padStart(3, "0")
                                    : index} */}
                                  {formattedNumber}
                                </label>
                              </li>
                            );
                          }
                          // else, render a seat
                          // <li class="seat">
                          //   <input
                          //     type="checkbox"
                          //     id={index + 1}
                          //     onClick={() => {
                          //       if (
                          //         tickets.some(
                          //           (ticket) => ticket.ticket === index + 1
                          //         )
                          //       ) {
                          //         setTickets([
                          //           ...tickets.filter(
                          //             (ticket) => ticket.ticket !== index + 1
                          //           ),
                          //         ]);
                          //       } else {
                          //         setTickets([
                          //           ...tickets,
                          //           { ticket: index + 1 },
                          //         ]);
                          //       }
                          //     }}
                          //   />
                          //   <label for={index + 1}>{index + 1}</label>
                          // </li>
                        )}
                      </ol>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <h5>Políticas de funcionamiento de la rifa:</h5>
                <p
                  className="my-2"
                  style={{ textAlign: "justify", lineHeight: "1.5" }}
                >
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Omnis blanditiis quaerat consequuntur accusantium delectus
                  debitis accusamus nemo cumque natus voluptates aut numquam
                  corrupti, id nisi quia suscipit at perspiciatis adipisci porro
                  quibusdam a? Quis necessitatibus veritatis nobis recusandae
                  facere mollitia ex odio voluptatum sunt veniam laboriosam,
                  amet voluptatibus vitae numquam?
                </p>

                {!auth.idUser && (
                  <p>
                    Debes <Link to="/login">iniciar sesión</Link> o{" "}
                    <Link to="/register">registrarte</Link> para comprar un
                    boleto.
                  </p>
                )}

                {/* <div className="product__bids__input">
                  {user && counterValue() > 0 ? (
                    <Form onSubmit={handleSubmit} id="bid-form">
                      <Row>
                        <Form.Group
                          className="mt-1"
                          controlId="formName"
                          as={Col}
                          xs="6"
                          lg="6"
                        >
                          <Form.Label>Nombre de usuario:</Form.Label>

                          <Form.Control
                            name="user"
                            plaintext
                            defaultValue={user?.username}
                            readOnly
                          />
                          <Form.Text className="text-muted">
                            Este es tu nombre de usuario
                          </Form.Text>
                        </Form.Group>
                        <Form.Group
                          className="mb-2"
                          controlId="formBid"
                          as={Col}
                          xs="6"
                          lg="6"
                        >
                          <Form.Label>Oferta:</Form.Label>
                          <div className="bid-container">
                            <button
                              type="button"
                              className="counter-action"
                              disabled={
                                count <=
                                bids[bids?.length - 1]?.bid +
                                  product?.data.pujaSubsecuente
                              }
                              onClick={handleDecrement}
                            >
                              -
                            </button>
                            <h5 className="form-control">
                              {formatMoney(count)}
                            </h5>
                            <button
                              type="button"
                              className="counter-action"
                              onClick={handleIncrement}
                            >
                              +
                            </button>
                          </div>
                        </Form.Group>
                      </Row>
                      <Button className="send" type="submit">
                        Enviar puja
                      </Button>
                    </Form>
                  ) : (
                    <></>
                  )}
                </div> */}
                <div className="product__share">
                  <span>Compartir:</span>
                  <div className="ms-2">
                    <FacebookShare
                      url={`https://larelojeriair.com/bid-product/${product?.id}`}
                    />
                    <WhatsAppShare
                      url={`https://larelojeriair.com/bid-product/${product?.id}`}
                    />
                  </div>
                </div>
                {/* <p className="product__price">
                  {formatMoney(product?.data.precio)} MXN
                </p> */}

                <div className="product__details">
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconGeneral}
                        alt="Información general"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Información general
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p style={{ textTransform: "capitalize" }}>
                          {product?.informacionGeneral.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconCase}
                        alt="Carcasa"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Carcasa
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p style={{ textTransform: "capitalize" }}>
                          {product?.carcasa.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconPointer}
                        alt="Carátula"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Carátula
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section ">
                        <p style={{ textTransform: "capitalize" }}>
                          {product?.caratula.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconStrap}
                        alt="Correa"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Correa
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p style={{ textTransform: "capitalize" }}>
                          {product?.correa.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* {tickets.length > 0 && (
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="product__bid__ticket-info">
              Accordion Item #1
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex align-items-center">
                <h6>tickets:</h6>
                {tickets.map((ticket, key) => (
                  <p key={key}>{ticket.ticket},</p>
                ))}
              </div>
              <div className="d-flex align-items-center">
                <h4>Total: {formatMoney(tickets.length * 250)}</h4>
              </div>
              <div>
                <a href="#credit-card">Pagar</a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )} */}

      {tickets.length > 0 &&
        product?.boletosPagados < product?.boletosTotales && (
          <div className="product__bid__ticket-info">
            <div
              className="product__bid__ticket-info__header d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center product__bid__ticket-info__tickets">
                <h6>boletos:</h6>
                {tickets.map((ticket, key) => (
                  <p key={key}>
                    {ticket.ticket.toString().length < 2
                      ? `0${ticket.ticket}`
                      : ticket.ticket}
                    ,
                  </p>
                ))}
              </div>
              <div className="d-flex align-items-center">
                <h4>
                  Total: {formatMoney(tickets.length * product?.precioBoleto)}
                </h4>
              </div>
              <div className="d-flex align-items-center">
                {/* <a href="#credit-card">Pagar</a> */}
                <button
                  type="button"
                  className="collapsible continue"
                  onClick={(e) => {
                    document
                      .querySelector(".content")
                      .classList.toggle("active");
                    document
                      .querySelector(".continue")
                      .classList.toggle("continue-active");
                    // document.querySelector(".continue").innerHTML("hola");
                    var content = document.querySelector(".content");
                    // console.log("hola");
                    if (window.innerWidth > 991) {
                      if (content.style.height === "auto") {
                        content.style.height = "0";
                        // console.log("a 0");
                      } else {
                        content.style.height = "auto";
                        // content.style.display = "block";
                        // console.log("a 210px");
                      }
                    } else {
                      if (content.style.height === "250px") {
                        content.style.height = "0";
                      } else {
                        content.style.height = "250px";
                      }
                    }
                  }}
                >
                  Continuar al pago
                </button>
              </div>
            </div>
            <div className="content">
              <div className="content-heading">
                <img src={lockIconUrl} alt="La Relojeriair" />
                <h4>Pagos seguros</h4>
                <div className="content-media">
                  <img src={creditCard1Url} alt="La Relojeriair" />
                  <img src={creditCard2Url} alt="La Relojeriair" />
                  <img src={creditCard3Url} alt="La Relojeriair" />
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              {!auth.idUser ? (
                <p className="text-center mt-3">
                  Debes <Link to="/login">iniciar sesión</Link> o{" "}
                  <Link to="/register">registrarte</Link> para comprar un
                  boleto.
                </p>
              ) : (
                <>
                  <Elements stripe={stripeLib}>
                    <PaymentForm
                      auth={auth}
                      user={user}
                      product={product}
                      tickets={tickets}
                      setTickets={setTickets}
                      paymentLoading={paymentLoading}
                      purchasedTickets={product?.purchasedTickets}
                      setPaymentLoading={setPaymentLoading}
                      logout={logout}
                      total={tickets.length * product?.precioBoleto}
                      error={error}
                      setError={setError}
                    />
                  </Elements>
                </>
              )}
              {error && <p className="stripe-error-feedback">{error}</p>}
            </div>
          </div>
        )}
    </section>
  );
}
