import React, { useState, useEffect, useMemo } from "react";
import { Switch, Route } from "react-router-dom";

// Custom components
import CustomNav from "./components/CustomNav/CustomNav";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Footer from "./components/Footer/Footer";
import SessionTimeout from "./components/SessionTimeout/SessionTimeout";

// Context
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
import { ProductsProvider } from "./context/ProductsContext";
import { AlertProvider } from "./context/AlertContext";

import { setToken, getToken, removeToken } from "./api/token";
import {
  addProductCart,
  countProductsCart,
  decreaseProductCart,
  deleteCartItemApi,
  getProductsCart,
  getTotalCartPrice,
  increaseProductCart,
  removeAllProductsCart,
} from "./api/cart";
import jwtDecode from "jwt-decode";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Catalogue from "./pages/Catalogue";
import Cart from "./pages/Cart";
import Product from "./pages/Product";
import Comments from "./pages/Comments";
import Checkout from "./pages/Checkout";
import Confirmation from "./pages/Confirmation";
import Profile from "./pages/Profile";
import Order from "./pages/Order";
import Bids from "./pages/Bids";
import Brand from "./pages/Brand";
import AuctionProduct from "./pages/AuctionProduct";
import Genre from "./pages/Genre";
import Condition from "./pages/Condition";
import Raffles from "./pages/Raffles";
import BidProduct from "./components/BidProduct/BidProduct";
import BidIndividualProduct from "./pages/BidIndividualProduct";
import TicketConfirmation from "./pages/TicketConfirmation";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";

function App() {
  const [auth, setAuth] = useState(undefined);
  const [realoadUser, setReloadUser] = useState(false);
  const [totalProductsCart, setTotalProductsCart] = useState(0);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  const [reloadCart, setReloadCart] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const token = getToken();
  //   if (token) {
  //     setAuth({
  //       token,
  //       idUser: jwtDecode(token).id,
  //     });
  //   } else {
  //     setAuth(null);
  //   }
  //   setReloadUser(false);
  // }, [realoadUser]);

  // useEffect(() => {
  //   const token = getToken();
  //   if (token) {
  //     (async () => {
  //       setTotalProductsCart(await countProductsCart(auth?.idUser, logout));
  //       // setTotalPriceCart(await getTotalCartPrice(auth?.idUser, logout));
  //       setReloadCart(false);
  //     })(logout);
  //   }
  // }, [reloadCart, auth]);

  // useEffect(() => {
  //   (async () => {
  //     setTotalPriceCart(await getTotalCartPrice(auth?.idUser, logout));
  //     setReloadCart(false);
  //   })(logout);
  //   // if (token) {
  //   // }
  // }, [reloadCart, auth]);

  // const login = (token) => {
  //   setToken(token);
  //   setAuth({
  //     token,
  //     idUser: jwtDecode(token).id,
  //   });
  // };

  // const logout = () => {
  //   if (auth) {
  //     removeToken();
  //     setAuth(null);
  //   }
  //   // history.push("/login");
  // };
  // // console.log(countProductsCart(6, logout));
  // const authData = useMemo(
  //   () => ({
  //     auth,
  //     login,
  //     logout,
  //     setReloadUser,
  //   }),
  //   [auth]
  // );
  // const addProduct = (product, quantity, logout) => {
  //   setLoading(true);
  //   const token = getToken();
  //   if (token) {
  //     addProductCart(product, quantity, logout);
  //     setReloadCart(true);
  //     setLoading(false);
  //   } else {
  //     console.error("Para comprar un producto tienes que iniciar sesión");
  //   }
  // };

  // const increaseProduct = (userId, product, quantity, productId, logout) => {
  //   // console.log("hola");
  //   increaseProductCart(userId, product, quantity, productId, logout);
  //   setReloadCart(true);
  // };
  // const decreaseProduct = (userId, product, quantity, productId, logout) => {
  //   // console.log("hola");
  //   decreaseProductCart(userId, product, quantity, productId, logout);
  //   setReloadCart(true);
  // };

  // const removeProduct = (userId, productId, logout) => {
  //   // console.log("hola");
  //   deleteCartItemApi(userId, productId, logout);
  //   setReloadCart(true);
  //   // const token = getToken();
  //   // if (token) {
  //   // } else {
  //   //   console.error("Para comprar un producto tienes que iniciar sesión");
  //   // }
  // };

  // const resetCart = (cartId, logout) => {
  //   // console.log("hola");
  //   // setTotalProductsCart(0);
  //   // setTotalPriceCart(0);
  //   removeAllProductsCart(cartId, logout);
  //   setReloadCart(true);
  // };

  // const cartData = useMemo(
  //   () => ({
  //     productsCart: totalProductsCart,
  //     total: totalPriceCart,
  //     addProductCart: (product, quantity, stock) =>
  //       addProduct(product, quantity, stock),
  //     getProductsCart: (userId) => getProductsCart(userId),
  //     removeProductCart: (userId, productId) =>
  //       removeProduct(userId, productId),
  //     increaseProductCart: (userId, product, quantity, productId) =>
  //       increaseProduct(userId, product, quantity, productId),
  //     decreaseProductCart: (userId, product, quantity, productId) =>
  //       decreaseProduct(userId, product, quantity, productId),
  //     removeAllProductsCart: (cartId) => resetCart(cartId),
  //     getTotalCartPrice: (userId) => getTotalCartPrice(userId),
  //     countProductsCart: (userId) => countProductsCart(userId),
  //   }),
  //   [totalProductsCart, totalPriceCart]
  // );

  // if (auth === undefined) return null;
  return (
    <AuthProvider>
      <ProductsProvider>
        <AlertProvider>
          <CartProvider>
            <ScrollToTop />
            <CustomNav />
            <main>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/brand/:slug">
                  <Brand />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/register">
                  <Register />
                </Route>
                <Route exact path="/catalogue">
                  <Catalogue />
                </Route>
                <Route exact path="/bids">
                  <Bids />
                </Route>
                <Route exact path="/bid-product/:id">
                  <BidIndividualProduct />
                </Route>
                <Route exact path="/raffles">
                  <Raffles />
                </Route>
                <Route exact path="/comments">
                  <Comments />
                </Route>
                <Route exact path="/product/:id">
                  <Product />
                </Route>
                <Route exact path="/genre/:slug">
                  <Genre />
                </Route>
                <Route exact path="/condition/:slug">
                  <Condition />
                </Route>
                <Route exact path="/auction-product/:id">
                  <AuctionProduct />
                </Route>
                <Route exact path="/cart">
                  <Cart />
                </Route>
                <Route exact path="/checkout">
                  <Checkout />
                </Route>
                <Route exact path="/confirmation/:id">
                  <Confirmation />
                </Route>
                <Route exact path="/ticket-confirmation/:id">
                  <TicketConfirmation />
                </Route>
                <Route exact path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/profile/orders/:id">
                  <Order />
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/reset-password">
                  <ResetPassword />
                </Route>
                <Route exact path="/verify-email">
                  <VerifyEmail />
                </Route>
              </Switch>
            </main>
            <Footer />
            <SessionTimeout />
          </CartProvider>
        </AlertProvider>
      </ProductsProvider>
    </AuthProvider>
  );
}

export default App;
